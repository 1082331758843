<template>
  <div
    class="row position-relative"
    :class="
      RoutePage == 'QuizFinal' ? 'm-0 p-0 w-100 big-div-quiz' : ' w-85 big-div'
    "
    style="z-index: 100"
    :style="getPageDesign()"
  >
    <!-- BackGroundDiv start-->
    <script2 v-if="pageDesign.pageStyles.jsCode">
      {{ pageDesign.pageStyles.jsCode }}
    </script2>
    <div
      class="bg lazyload"
      :data-bg="MainImgSrc(getImageSrc(pageDesign.imageBlock, isDesktopView))"
      v-if="getImageAlign == 'no'"
      :key="getImageSrc(pageDesign.imageBlock, isDesktopView)"
      :style="
        getbackgroundImage(
          IsImageAdded,
          pageDesign.imageBlock,
          getImageAlign,
          isDesktopView
        ) + getBgDivStyles()
      "
    ></div>
    <!-- BackGroundDiv end-->
    <div v-if="showAlert" class="AlertContainer">
      <div
        class="alert text-center fade show text-white alert-text"
        role="alert"
        style="background: #f27367; width: fit-content"
      >
        <span
          ><i
            v-if="!ShowOtpIcon"
            class="fa fa-exclamation-triangle mr-2"
            aria-hidden="true"
          ></i>
          {{ alertMsg }}
        </span>
      </div>
    </div>
    <!-- Image Align Left -->
    <div
      class="px-0"
      v-show="showLeftImage"
      :class="[
        {
          'col-12 col-lg-12 col-xs-12': !isDesktopView,
          'image-division col-6': isDesktopView,
        },
      ]"
      :style="
        calculateImageWidth(
          pageDesign.imageBlock,
          isDesktopView,
          IsImageVariableWidthActive
        )
      "
    >
      <div :class="isDesktopView ? 'image-division' : ''">
        <div :style="height100(isDesktopView)">
          <LazyImage
            :key="getImageSrc(pageDesign.imageBlock, isDesktopView)"
            :style="
              getUniversalImageStyle(
                isDesktopView,
                pageDesign.imageBlock.style,
                IsImageVariableWidthActive,
                pageDesign.imageBlock
              ) +
              getImageRotation(pageDesign.imageBlock.style) +
              getImageOpacity(pageDesign.imageBlock.style)
            "
            :class="[
              `pic-${getSelectedPage.order}`,
              getImageSrc(pageDesign.imageBlock, isDesktopView) ==
              '/images/default_start_page.png'
                ? 'default-bg'
                : '',
            ]"
            :src="MainImgSrc(getImageSrc(pageDesign.imageBlock, isDesktopView))"
            alt
          />
        </div>
      </div>
    </div>

    <!-- Main Section -->
    <div
      :class="[!IsImageAdded ? 'col-12' : imageAddedLayout]"
      :style="
        getMainPaddingCss(pageDesign.pageStyles, isDesktopView) +
        getScrollCSS(
          pageDesign.pageStyles,
          isDesktopView,
          pageDesign.imageBlock.style,
          pageDesign.pageType
        ) +
        calculateContentWidth(
          pageDesign.imageBlock,
          isDesktopView,
          IsImageVariableWidthActive,
          pageDesign.pageStyles
        )
      "
    >
      <div
        style="
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          height: 100%;
        "
      >
        <div style="overflow: hidden scroll" class="h-100">
          <div>
            <PreviewProgressBar
              :order="CurrentPage.order"
              v-if="!isBearWithBenefitsClient"
            />
          </div>
          <div
            v-for="(pro, ind) in pageDesign.blocksArray"
            :key="'preview' + ind"
            :class="[
              'item-' + ind,
              pro.type === 'tooltip' ? 'tooltip-zIndex' : '',
            ]"
            :data-aos="animationType"
            :data-aos-duration="getAnimationSpeedinMillis"
            :data-aos-delay="AOSAnimationDelay(ind)"
            :data-aos-anchor="`.item-${ind == 0 ? ind : ind - 1}`"
          >
            <div
              id="preview"
              :style="getHeightWidth(pro.style)"
              class="content"
              style="position: relative"
            >
              <div
                v-if="pro.type == 'text'"
                :style="
                  getColorCss(pro.style) +
                  getPaddingCss(pro.style) +
                  getMarginCss(pro.style, isDesktopView) +
                  getColorWithOpacity(pro.style) +
                  getTextRotate(pro.style) +
                  getLineHeight(pro.style) +
                  getTextAlign(pro.style) +
                  getFontStyle(pro.style, isDesktopView)
                "
                v-html="getTextValue(pro.content)"
              ></div>
              <div
                v-if="pro.type == 'html'"
                :style="
                  getMarginCss(pro.style, isDesktopView) +
                  getColorWithOpacity(pro.style) +
                  getTextRotate(pro.style) +
                  getTextAlign(pro.style)
                "
              >
                <HtmlElement :pro="pro" :isDesktopView="isDesktopView" />
              </div>
              <!-- Free Text Input -->

              <div v-if="pro.type == 'freeText' && !showAuthBlock">
                <div
                  :style="
                    getMarginCss(pro.style, isDesktopView) +
                    getTextAlign(pro.style)
                  "
                >
                  <div class="mb-1" v-if="pro.showLabel">
                    <p
                      class="m-0 p-0 text-left"
                      :style="leadLabelStyle(pro.style)"
                    >
                      {{ pro.labelText }}
                      <span v-if="pro.isRequired" style="color: red">*</span>
                    </p>
                  </div>
                  <div
                    :style="
                      pro.style.borderType === 'border'
                        ? getInputBorderCSS(pro.style)
                        : getInputBorderBottomCSS(pro.style)
                    "
                  >
                    <input
                      v-if="
                        pro.selectedInfoType.fieldName !== 'date' &&
                        pro.selectedInfoType.fieldName !== 'phone_number'
                      "
                      :type="pro.selectedInfoType.type.toLowerCase()"
                      :placeholder="pro.InfoTypePlaceholder"
                      @keyup.enter="initiateSaveLeadData"
                      :required="pro.isRequired"
                      class="text-dark font-weight-medium input-element CustomTextInput"
                      :style="customInputStyles(pro.style)"
                      v-model="pro.selectedInfoType.fieldValue"
                      autofocus
                    />

                    <div
                      v-else-if="
                        pro.selectedInfoType.fieldName !== 'date' &&
                        pro.selectedInfoType.fieldName == 'phone_number'
                      "
                    >
                      <VueTelInput
                        v-model="pro.selectedInfoType.fieldValue"
                        :inputOptions="{
                          placeholder: pro.InfoTypePlaceholder,
                          maxlength: 15,
                        }"
                        :dropdownOptions="dropdown"
                        mode="international"
                        :required="pro.isRequired"
                         @keydown.native.enter="initiateSaveLeadData"
                        ref="telInput"
                        :validCharactersOnly="true"
                        class="text-dark font-weight-medium input-element CustomTextInput telephoneInput"
                        :style="customInputStyles(pro.style)"
                        autofocus
                      />
                    </div>

                    <div v-else>
                      <div class="row m-0 p-0" v-if="pro.isDatePicker">
                        <!-- <b-form-datepicker  class="CustomDatePicker" :style="customInputStyles(pro.style)" v-model="datePickerValue"></b-form-datepicker> -->
                        <!-- +`color: var(--inputColor) !important;` -->
                        <div @click="openCalender" class="w-100">
                          <input
                            class="form-control defaultCalendar"
                            type="date"
                            v-model="datePickerValue"
                            :style="customInputStyles(pro.style)"
                            :placeholder="
                              pro.selectedInfoType.datePickerPlaceholder
                            "
                            ref="CustomDateInputRef"
                          />
                        </div>
                      </div>

                      <div class="row m-0 p-0" v-else>
                        <div
                          class="d-flex"
                          v-if="pro.selectedDateFormat == 'DDMMYYYY'"
                        >
                          <div class="d-flex flex-column px-1">
                            <div class="d-inline" style="text-align: left">
                              <span
                                :style="
                                  getDateTextColor(pro.style) +
                                  getDateTextFontStyle(pro.style)
                                "
                                >{{ pro.selectedInfoType.dateText }}</span
                              >
                            </div>

                            <div>
                              <input
                                :type="pro.selectedInfoType.type.toLowerCase()"
                                placeholder="DD"
                                :required="pro.isRequired"
                                :min="1"
                                :max="31"
                                class="text-dark font-weight-medium input-element CustomTextInput"
                                :style="customInputStyles(pro.style)"
                                v-model="dateInputValue"
                                autofocus
                              />
                            </div>
                          </div>
                          <!-- seperator -->
                          <div class="d-flex justify-content-center mr-2">
                            <span
                              class="mb-0"
                              style="align-self: end"
                              :style="getDateTextColor(pro.style)"
                              >{{ pro.selectedDateFormatSeperator }}</span
                            >
                          </div>
                          <!-- seperator -->
                          <div class="d-flex flex-column">
                            <div class="d-inline" style="text-align: left">
                              <span
                                :style="
                                  getDateTextColor(pro.style) +
                                  getDateTextFontStyle(pro.style)
                                "
                                >{{ pro.selectedInfoType.monthText }}</span
                              >
                            </div>

                            <div>
                              <input
                                :type="pro.selectedInfoType.type.toLowerCase()"
                                placeholder="MM"
                                :required="pro.isRequired"
                                :min="1"
                                :max="12"
                                class="text-dark font-weight-medium input-element CustomTextInput"
                                :style="customInputStyles(pro.style)"
                                v-model="monthInputValue"
                                autofocus
                              />
                            </div>
                          </div>
                          <!-- seperator -->
                          <div class="d-flex justify-content-center mr-2">
                            <span
                              class="mb-0"
                              style="align-self: end"
                              :style="getDateTextColor(pro.style)"
                              >{{ pro.selectedDateFormatSeperator }}</span
                            >
                          </div>
                          <!-- seperator -->
                          <div class="d-flex flex-column">
                            <div class="d-inline" style="text-align: left">
                              <span
                                :style="
                                  getDateTextColor(pro.style) +
                                  getDateTextFontStyle(pro.style)
                                "
                                >{{ pro.selectedInfoType.yearText }}</span
                              >
                            </div>

                            <div>
                              <input
                                :type="pro.selectedInfoType.type.toLowerCase()"
                                placeholder="YYYY"
                                :min="1900"
                                :max="9999"
                                :required="pro.isRequired"
                                class="text-dark font-weight-medium input-element CustomTextInput"
                                :style="customInputStyles(pro.style)"
                                v-model="yearInputValue"
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          class="d-flex"
                          v-if="pro.selectedDateFormat == 'DDMM'"
                        >
                          <div class="d-flex flex-column px-1">
                            <div class="d-inline" style="text-align: left">
                              <span
                                :style="
                                  getDateTextColor(pro.style) +
                                  getDateTextFontStyle(pro.style)
                                "
                                >{{ pro.selectedInfoType.dateText }}</span
                              >
                            </div>

                            <div>
                              <input
                                :type="pro.selectedInfoType.type.toLowerCase()"
                                placeholder="DD"
                                :required="pro.isRequired"
                                :min="1"
                                :max="31"
                                class="text-dark font-weight-medium input-element CustomTextInput"
                                :style="customInputStyles(pro.style)"
                                v-model="dateInputValue"
                                autofocus
                              />
                            </div>
                          </div>
                          <!-- seperator -->
                          <div class="d-flex justify-content-center mr-2">
                            <span
                              class="mb-0"
                              style="align-self: end"
                              :style="getDateTextColor(pro.style)"
                              >{{ pro.selectedDateFormatSeperator }}</span
                            >
                          </div>
                          <!-- seperator -->
                          <div class="d-flex flex-column">
                            <div class="d-inline" style="text-align: left">
                              <span
                                :style="
                                  getDateTextColor(pro.style) +
                                  getDateTextFontStyle(pro.style)
                                "
                                >{{ pro.selectedInfoType.monthText }}</span
                              >
                            </div>

                            <div>
                              <input
                                :type="pro.selectedInfoType.type.toLowerCase()"
                                placeholder="MM"
                                :required="pro.isRequired"
                                :min="1"
                                :max="12"
                                class="text-dark font-weight-medium input-element CustomTextInput"
                                :style="customInputStyles(pro.style)"
                                v-model="monthInputValue"
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          class="d-flex"
                          v-if="pro.selectedDateFormat == 'MMDDYYYY'"
                        >
                          <div class="d-flex flex-column">
                            <div class="d-inline" style="text-align: left">
                              <span
                                :style="
                                  getDateTextColor(pro.style) +
                                  getDateTextFontStyle(pro.style)
                                "
                                >{{ pro.selectedInfoType.monthText }}</span
                              >
                            </div>

                            <div>
                              <input
                                :type="pro.selectedInfoType.type.toLowerCase()"
                                placeholder="MM"
                                :required="pro.isRequired"
                                :min="1"
                                :max="12"
                                class="text-dark font-weight-medium input-element CustomTextInput"
                                :style="customInputStyles(pro.style)"
                                v-model="monthInputValue"
                                autofocus
                              />
                            </div>
                          </div>
                          <!-- seperator -->
                          <div class="d-flex justify-content-center mr-2">
                            <span
                              class="mb-0"
                              style="align-self: end"
                              :style="getDateTextColor(pro.style)"
                              >{{ pro.selectedDateFormatSeperator }}</span
                            >
                          </div>
                          <!-- seperator -->
                          <div class="d-flex flex-column">
                            <div class="d-inline" style="text-align: left">
                              <span
                                :style="
                                  getDateTextColor(pro.style) +
                                  getDateTextFontStyle(pro.style)
                                "
                                >{{ pro.selectedInfoType.dateText }}</span
                              >
                            </div>

                            <div>
                              <input
                                :type="pro.selectedInfoType.type.toLowerCase()"
                                placeholder="DD"
                                :required="pro.isRequired"
                                :min="1"
                                :max="31"
                                class="text-dark font-weight-medium input-element CustomTextInput"
                                :style="customInputStyles(pro.style)"
                                v-model="dateInputValue"
                              />
                            </div>
                          </div>

                          <!-- seperator -->
                          <div class="d-flex justify-content-center mr-2">
                            <span
                              class="mb-0"
                              style="align-self: end"
                              :style="getDateTextColor(pro.style)"
                              >{{ pro.selectedDateFormatSeperator }}</span
                            >
                          </div>
                          <!-- seperator -->
                          <div class="d-flex flex-column">
                            <div class="d-inline" style="text-align: left">
                              <span
                                :style="
                                  getDateTextColor(pro.style) +
                                  getDateTextFontStyle(pro.style)
                                "
                                >{{ pro.selectedInfoType.yearText }}</span
                              >
                            </div>

                            <div>
                              <input
                                :type="pro.selectedInfoType.type.toLowerCase()"
                                placeholder="YYYY"
                                :min="1900"
                                :max="9999"
                                :required="pro.isRequired"
                                class="text-dark font-weight-medium input-element CustomTextInput"
                                :style="customInputStyles(pro.style)"
                                v-model="yearInputValue"
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          class="d-flex"
                          v-if="pro.selectedDateFormat == 'MMDD'"
                        >
                          <div class="d-flex flex-column">
                            <div class="d-inline" style="text-align: left">
                              <span
                                :style="
                                  getDateTextColor(pro.style) +
                                  getDateTextFontStyle(pro.style)
                                "
                                >{{ pro.selectedInfoType.monthText }}</span
                              >
                            </div>

                            <div>
                              <input
                                :type="pro.selectedInfoType.type.toLowerCase()"
                                placeholder="MM"
                                :required="pro.isRequired"
                                :min="1"
                                :max="12"
                                class="text-dark font-weight-medium input-element CustomTextInput"
                                :style="customInputStyles(pro.style)"
                                v-model="monthInputValue"
                                autofocus
                              />
                            </div>
                          </div>
                          <!-- seperator -->
                          <div class="d-flex justify-content-center mr-2">
                            <span
                              class="mb-0"
                              style="align-self: end"
                              :style="getDateTextColor(pro.style)"
                              >{{ pro.selectedDateFormatSeperator }}</span
                            >
                          </div>
                          <!-- seperator -->
                          <div class="d-flex flex-column">
                            <div class="d-inline" style="text-align: left">
                              <span
                                :style="
                                  getDateTextColor(pro.style) +
                                  getDateTextFontStyle(pro.style)
                                "
                                >{{ pro.selectedInfoType.dateText }}</span
                              >
                            </div>

                            <div>
                              <input
                                :type="pro.selectedInfoType.type.toLowerCase()"
                                placeholder="DD"
                                :required="pro.isRequired"
                                :min="1"
                                :max="31"
                                class="text-dark font-weight-medium input-element CustomTextInput"
                                :style="customInputStyles(pro.style)"
                                v-model="dateInputValue"
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          class="d-flex"
                          v-if="pro.selectedDateFormat == 'YYYYMMDD'"
                        >
                          <div class="d-flex flex-column">
                            <div class="d-inline" style="text-align: left">
                              <span
                                :style="
                                  getDateTextColor(pro.style) +
                                  getDateTextFontStyle(pro.style)
                                "
                                >{{ pro.selectedInfoType.yearText }}</span
                              >
                            </div>

                            <div>
                              <input
                                :type="pro.selectedInfoType.type.toLowerCase()"
                                placeholder="YYYY"
                                :min="1900"
                                :max="9999"
                                :required="pro.isRequired"
                                class="text-dark font-weight-medium input-element CustomTextInput"
                                :style="customInputStyles(pro.style)"
                                v-model="yearInputValue"
                                autofocus
                              />
                            </div>
                          </div>
                          <!-- seperator -->
                          <div class="d-flex justify-content-center mr-2">
                            <span
                              class="mb-0"
                              style="align-self: end"
                              :style="getDateTextColor(pro.style)"
                              >{{ pro.selectedDateFormatSeperator }}</span
                            >
                          </div>
                          <!-- seperator -->
                          <div class="d-flex flex-column">
                            <div class="d-inline" style="text-align: left">
                              <span
                                :style="
                                  getDateTextColor(pro.style) +
                                  getDateTextFontStyle(pro.style)
                                "
                                >{{ pro.selectedInfoType.monthText }}</span
                              >
                            </div>

                            <div>
                              <input
                                :type="pro.selectedInfoType.type.toLowerCase()"
                                placeholder="MM"
                                :required="pro.isRequired"
                                :min="1"
                                :max="12"
                                class="text-dark font-weight-medium input-element CustomTextInput"
                                :style="customInputStyles(pro.style)"
                                v-model="monthInputValue"
                              />
                            </div>
                          </div>
                          <!-- seperator -->
                          <div class="d-flex justify-content-center mr-2">
                            <span
                              class="mb-0"
                              style="align-self: end"
                              :style="getDateTextColor(pro.style)"
                              >{{ pro.selectedDateFormatSeperator }}</span
                            >
                          </div>
                          <!-- seperator -->
                          <div class="d-flex flex-column">
                            <div class="d-inline" style="text-align: left">
                              <span
                                :style="
                                  getDateTextColor(pro.style) +
                                  getDateTextFontStyle(pro.style)
                                "
                                >{{ pro.selectedInfoType.dateText }}</span
                              >
                            </div>

                            <div>
                              <input
                                :type="pro.selectedInfoType.type.toLowerCase()"
                                placeholder="DD"
                                :required="pro.isRequired"
                                :min="1"
                                :max="31"
                                class="text-dark font-weight-medium input-element CustomTextInput"
                                :style="customInputStyles(pro.style)"
                                v-model="dateInputValue"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="pro.type == 'customInput' && !showAuthBlock">
                <div
                  :style="
                    getMarginCss(pro.style, isDesktopView) +
                    getTextAlign(pro.style)
                  "
                >
                  <div class="mb-1" v-if="pro.showLabel">
                    <p
                      class="m-0 p-0 text-left"
                      :style="leadLabelStyle(pro.style)"
                    >
                      {{ pro.labelText }}
                      <span v-if="pro.isRequired" style="color: red">*</span>
                    </p>
                  </div>
                  <div
                    :style="
                      pro.style.borderType === 'border'
                        ? getInputBorderCSS(pro.style)
                        : getInputBorderBottomCSS(pro.style)
                    "
                  >
                    <input
                      v-if="pro.selectedInfoType.type == 'text'"
                      :type="pro.selectedInfoType.type.toLowerCase()"
                      :placeholder="pro.InfoTypePlaceholder"
                      :required="pro.isRequired"
                      class="text-dark font-weight-medium input-element CustomTextInput"
                      :style="customInputStyles(pro.style)"
                      v-model="pro.selectedInfoType.fieldValue"
                      autofocus
                       @keyup.enter="initiateSaveLeadData"
                    />

                    <textarea
                      v-if="pro.selectedInfoType.type == 'textarea'"
                      type="text"
                      :placeholder="pro.InfoTypePlaceholder"
                      :required="pro.isRequired"
                      v-model="pro.selectedInfoType.fieldValue"
                      rows="1"
                      :style="freeTextInputStyles(pro.style)"
                      id="textInput"
                      style="overflow: auto"
                      class="text-input p-1"
                      @input="handleCustomTextAreaInputChange(pro)"
                      @keyup="InputAdjust"
                      @focus="InputAdjust"
                      autofocus
                       @keyup.enter="initiateSaveLeadData"
                    />
                    <!-- resize: none; -->

                    <div v-if="pro.selectedInfoType.type == 'date'">
                      <div class="row m-0 p-0" v-if="pro.isDatePicker">
                        <!-- <b-form-datepicker  class="CustomDatePicker" :style="customInputStyles(pro.style)" v-model="datePickerValue"></b-form-datepicker> -->
                        <div @click="openCalender" class="w-100">
                          <input
                            class="form-control defaultCalendar"
                            type="date"
                            v-model="datePickerCustomValue"
                            :style="
                              customInputStyles(pro.style) +
                              `color: var(--inputColor) !important;`
                            "
                            :placeholder="
                              pro.selectedInfoType.datePickerPlaceholder
                            "
                            ref="CustomDateInputRef"
                          />
                        </div>
                      </div>

                      <div class="row m-0 p-0" v-else>
                        <div
                          class="d-flex"
                          v-if="pro.selectedDateFormat == 'DDMMYYYY'"
                        >
                          <div class="d-flex flex-column px-1">
                            <div class="d-inline" style="text-align: left">
                              <span
                                :style="
                                  getDateTextColor(pro.style) +
                                  getDateTextFontStyle(pro.style)
                                "
                                >{{ pro.selectedInfoType.dateText }}</span
                              >
                            </div>

                            <div>
                              <input
                                :type="`text`"
                                placeholder="DD"
                                :required="pro.isRequired"
                                :min="1"
                                :max="31"
                                class="text-dark  font-weight-medium input-element CustomTextInput"
                                :style="customInputStyles(pro.style)"
                                v-model="dateCustomInputValue"
                                autofocus
                              />
                            </div>
                          </div>
                          <!-- seperator -->
                          <div class="d-flex justify-content-center mr-2">
                            <span
                              class="mb-0"
                              style="align-self: end"
                              :style="getDateTextColor(pro.style)"
                              >{{ pro.selectedDateFormatSeperator }}</span
                            >
                          </div>
                          <!-- seperator -->
                          <div class="d-flex flex-column">
                            <div class="d-inline" style="text-align: left">
                              <span
                                :style="
                                  getDateTextColor(pro.style) +
                                  getDateTextFontStyle(pro.style)
                                "
                                >{{ pro.selectedInfoType.monthText }}</span
                              >
                            </div>

                            <div>
                              <input
                                :type="`text`"
                                placeholder="MM"
                                :required="pro.isRequired"
                                :min="1"
                                :max="12"
                                class="text-dark font-weight-medium input-element CustomTextInput"
                                :style="customInputStyles(pro.style)"
                                v-model="monthCustomInputValue"
                              />
                            </div>
                          </div>
                          <!-- seperator -->
                          <div class="d-flex justify-content-center mr-2">
                            <span
                              class="mb-0"
                              style="align-self: end"
                              :style="getDateTextColor(pro.style)"
                              >{{ pro.selectedDateFormatSeperator }}</span
                            >
                          </div>
                          <!-- seperator -->
                          <div class="d-flex flex-column">
                            <div class="d-inline" style="text-align: left">
                              <span
                                :style="
                                  getDateTextColor(pro.style) +
                                  getDateTextFontStyle(pro.style)
                                "
                                >{{ pro.selectedInfoType.yearText }}</span
                              >
                            </div>

                            <div>
                              <input
                                :type="`text`"
                                placeholder="YYYY"
                                :min="1900"
                                :max="9999"
                                :required="pro.isRequired"
                                class="text-dark font-weight-medium input-element CustomTextInput"
                                :style="customInputStyles(pro.style)"
                                v-model="yearCustomInputValue"
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          class="d-flex"
                          v-if="pro.selectedDateFormat == 'DDMM'"
                        >
                          <div class="d-flex flex-column px-1">
                            <div class="d-inline" style="text-align: left">
                              <span
                                :style="
                                  getDateTextColor(pro.style) +
                                  getDateTextFontStyle(pro.style)
                                "
                                >{{ pro.selectedInfoType.dateText }}</span
                              >
                            </div>

                            <div>
                              <input
                                :type="`text`"
                                placeholder="DD"
                                :required="pro.isRequired"
                                :min="1"
                                :max="31"
                                class="text-dark font-weight-medium input-element CustomTextInput"
                                :style="customInputStyles(pro.style)"
                                v-model="dateCustomInputValue"
                                autofocus
                              />
                            </div>
                          </div>
                          <!-- seperator -->
                          <div class="d-flex justify-content-center mr-2">
                            <span
                              class="mb-0"
                              style="align-self: end"
                              :style="getDateTextColor(pro.style)"
                              >{{ pro.selectedDateFormatSeperator }}</span
                            >
                          </div>
                          <!-- seperator -->
                          <div class="d-flex flex-column">
                            <div class="d-inline" style="text-align: left">
                              <span
                                :style="
                                  getDateTextColor(pro.style) +
                                  getDateTextFontStyle(pro.style)
                                "
                                >{{ pro.selectedInfoType.monthText }}</span
                              >
                            </div>

                            <div>
                              <input
                                :type="`text`"
                                placeholder="MM"
                                :required="pro.isRequired"
                                :min="1"
                                :max="12"
                                class="text-dark font-weight-medium input-element CustomTextInput"
                                :style="customInputStyles(pro.style)"
                                v-model="monthCustomInputValue"
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          class="d-flex"
                          v-if="pro.selectedDateFormat == 'MMDDYYYY'"
                        >
                          <div class="d-flex flex-column">
                            <div class="d-inline" style="text-align: left">
                              <span
                                :style="
                                  getDateTextColor(pro.style) +
                                  getDateTextFontStyle(pro.style)
                                "
                                >{{ pro.selectedInfoType.monthText }}</span
                              >
                            </div>

                            <div>
                              <input
                                :type="`text`"
                                placeholder="MM"
                                :required="pro.isRequired"
                                :min="1"
                                :max="12"
                                class="text-dark font-weight-medium input-element CustomTextInput"
                                :style="customInputStyles(pro.style)"
                                v-model="monthCustomInputValue"
                                autofocus
                              />
                            </div>
                          </div>
                          <!-- seperator -->
                          <div class="d-flex justify-content-center mr-2">
                            <span
                              class="mb-0"
                              style="align-self: end"
                              :style="getDateTextColor(pro.style)"
                              >{{ pro.selectedDateFormatSeperator }}</span
                            >
                          </div>
                          <!-- seperator -->
                          <div class="d-flex flex-column">
                            <div class="d-inline" style="text-align: left">
                              <span
                                :style="
                                  getDateTextColor(pro.style) +
                                  getDateTextFontStyle(pro.style)
                                "
                                >{{ pro.selectedInfoType.dateText }}</span
                              >
                            </div>

                            <div>
                              <input
                                :type="`text`"
                                placeholder="DD"
                                :required="pro.isRequired"
                                :min="1"
                                :max="31"
                                class="text-dark font-weight-medium input-element CustomTextInput"
                                :style="customInputStyles(pro.style)"
                                v-model="dateCustomInputValue"
                              />
                            </div>
                          </div>

                          <!-- seperator -->
                          <div class="d-flex justify-content-center mr-2">
                            <span
                              class="mb-0"
                              style="align-self: end"
                              :style="getDateTextColor(pro.style)"
                              >{{ pro.selectedDateFormatSeperator }}</span
                            >
                          </div>
                          <!-- seperator -->
                          <div class="d-flex flex-column">
                            <div class="d-inline" style="text-align: left">
                              <span
                                :style="
                                  getDateTextColor(pro.style) +
                                  getDateTextFontStyle(pro.style)
                                "
                                >{{ pro.selectedInfoType.yearText }}</span
                              >
                            </div>

                            <div>
                              <input
                                :type="`text`"
                                placeholder="YYYY"
                                :min="1900"
                                :max="9999"
                                :required="pro.isRequired"
                                class="text-dark font-weight-medium input-element CustomTextInput"
                                :style="customInputStyles(pro.style)"
                                v-model="yearCustomInputValue"
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          class="d-flex"
                          v-if="pro.selectedDateFormat == 'MMDD'"
                        >
                          <div class="d-flex flex-column">
                            <div class="d-inline" style="text-align: left">
                              <span
                                :style="
                                  getDateTextColor(pro.style) +
                                  getDateTextFontStyle(pro.style)
                                "
                                >{{ pro.selectedInfoType.monthText }}</span
                              >
                            </div>

                            <div>
                              <input
                                :type="`text`"
                                placeholder="MM"
                                :required="pro.isRequired"
                                :min="1"
                                :max="12"
                                class="text-dark font-weight-medium input-element CustomTextInput"
                                :style="customInputStyles(pro.style)"
                                v-model="monthCustomInputValue"
                                autofocus
                              />
                            </div>
                          </div>
                          <!-- seperator -->
                          <div class="d-flex justify-content-center mr-2">
                            <span
                              class="mb-0"
                              style="align-self: end"
                              :style="getDateTextColor(pro.style)"
                              >{{ pro.selectedDateFormatSeperator }}</span
                            >
                          </div>
                          <!-- seperator -->
                          <div class="d-flex flex-column">
                            <div class="d-inline" style="text-align: left">
                              <span
                                :style="
                                  getDateTextColor(pro.style) +
                                  getDateTextFontStyle(pro.style)
                                "
                                >{{ pro.selectedInfoType.dateText }}</span
                              >
                            </div>

                            <div>
                              <input
                                :type="`text`"
                                placeholder="DD"
                                :required="pro.isRequired"
                                :min="1"
                                :max="31"
                                class="text-dark font-weight-medium input-element CustomTextInput"
                                :style="customInputStyles(pro.style)"
                                v-model="dateCustomInputValue"
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          class="d-flex"
                          v-if="pro.selectedDateFormat == 'YYYYMMDD'"
                        >
                          <div class="d-flex flex-column">
                            <div class="d-inline" style="text-align: left">
                              <span
                                :style="
                                  getDateTextColor(pro.style) +
                                  getDateTextFontStyle(pro.style)
                                "
                                >{{ pro.selectedInfoType.yearText }}</span
                              >
                            </div>

                            <div>
                              <input
                                :type="`text`"
                                placeholder="YYYY"
                                :min="1900"
                                :max="9999"
                                :required="pro.isRequired"
                                class="text-dark font-weight-medium input-element CustomTextInput"
                                :style="customInputStyles(pro.style)"
                                v-model="yearCustomInputValue"
                                autofocus
                              />
                            </div>
                          </div>
                          <!-- seperator -->
                          <div class="d-flex justify-content-center mr-2">
                            <span
                              class="mb-0"
                              style="align-self: end"
                              :style="getDateTextColor(pro.style)"
                              >{{ pro.selectedDateFormatSeperator }}</span
                            >
                          </div>
                          <!-- seperator -->
                          <div class="d-flex flex-column">
                            <div class="d-inline" style="text-align: left">
                              <span
                                :style="
                                  getDateTextColor(pro.style) +
                                  getDateTextFontStyle(pro.style)
                                "
                                >{{ pro.selectedInfoType.monthText }}</span
                              >
                            </div>

                            <div>
                              <input
                                :type="`text`"
                                placeholder="MM"
                                :required="pro.isRequired"
                                :min="1"
                                :max="12"
                                class="text-dark font-weight-medium input-element CustomTextInput"
                                :style="customInputStyles(pro.style)"
                                v-model="monthCustomInputValue"
                              />
                            </div>
                          </div>
                          <!-- seperator -->
                          <div class="d-flex justify-content-center mr-2">
                            <span
                              class="mb-0"
                              style="align-self: end"
                              :style="getDateTextColor(pro.style)"
                              >{{ pro.selectedDateFormatSeperator }}</span
                            >
                          </div>
                          <!-- seperator -->
                          <div class="d-flex flex-column">
                            <div class="d-inline" style="text-align: left">
                              <span
                                :style="
                                  getDateTextColor(pro.style) +
                                  getDateTextFontStyle(pro.style)
                                "
                                >{{ pro.selectedInfoType.dateText }}</span
                              >
                            </div>

                            <div>
                              <input
                                :type="`text`"
                                placeholder="DD"
                                :required="pro.isRequired"
                                :min="1"
                                :max="31"
                                class="text-dark font-weight-medium input-element CustomTextInput"
                                :style="customInputStyles(pro.style)"
                                v-model="dateCustomInputValue"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="
                      pro.selectedInfoType.type == 'textarea' &&
                      Object.prototype.hasOwnProperty.call(
                        pro,
                        `limitFreeTextArea`
                      ) &&
                      pro.limitFreeTextArea.limitFreeTextAreaValue
                    "
                    class="text-right pr-2"
                  >
                    <span
                      v-if="pro.limitFreeTextArea.limitSelectionType == 'max'"
                      :style="`color:${pro.style.color};opacity:${pro.style.textOpacity};`"
                      >{{ pro.selectedInfoType.fieldValue.length }}/{{
                        pro.limitFreeTextArea.maxFreeTextAreaValue
                      }}</span
                    >
                    <span
                      v-if="pro.limitFreeTextArea.limitSelectionType == 'min'"
                      :style="`color:${pro.style.color};opacity:${pro.style.textOpacity};`"
                      >{{ pro.limitFreeTextArea.minFreeTextAreaValue }}/{{
                        pro.selectedInfoType.fieldValue.length
                      }}</span
                    >
                    <span
                      v-if="pro.limitFreeTextArea.limitSelectionType == 'exact'"
                      :style="`color:${pro.style.color};opacity:${pro.style.textOpacity};`"
                      >{{ pro.selectedInfoType.fieldValue.length }}/{{
                        pro.limitFreeTextArea.exactFreeTextAreaValue
                      }}</span
                    >
                  </div>
                  <!-- Limit Characters Div end-->
                </div>
              </div>

              <!-- checkbox -->
              <div v-if="pro.type == 'checkbox' && !showAuthBlock">
                <div
                  :style="
                    getMarginCss(pro.style, isDesktopView) +
                    getTextAlign(pro.style)
                  "
                >
                  <div
                    class="d-flex align-items-center"
                    :style="getcheckboxRotate(pro.style)"
                  >
                    <div
                      class="form-check d-flex align-items-center w-100 checkboxDiv"
                      :style="`justify-content:${
                        pro.style.textAlign
                      };flex-direction:${
                        pro.style.textAlign == 'end' ? `row-reverse` : `row`
                      };`"
                    >
                      <input
                        type="checkbox"
                        class="form-check-input m-0"
                        :class="pro.style.textAlign == 'end' ? `ml-2` : `mr-2`"
                        :id="`customCheckbox-${ind}`"
                        :style="{
                          height: pro.style.fontSize + 'px',
                          width: pro.style.fontSize + 'px',
                          position: 'relative',
                        }"
                        v-model="pro.value"
                      />
                      <label
                        class="form-check-label m-0"
                        :for="`customCheckbox-${ind}`"
                        :style="
                          getColorCss(pro.style) +
                          getColorWithOpacity(pro.style) +
                          getFontStyle(pro.style)
                        "
                        ><span
                          >{{ pro.text }}
                          <a
                            class=""
                            style="text-decoration: underline; cursor: pointer"
                            :style="getLinkStyle(pro.style)"
                            @click="goToURLClicked(pro.link)"
                            target="_blank"
                            >{{ pro.linkText }}</a
                          ></span
                        ><span v-if="pro.isRequired" style="color: red"
                          >*</span
                        ></label
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="pro.type == 'freeTextArea' && !showAuthBlock">
                <div
                  :style="
                    getMarginCss(pro.style, isDesktopView) +
                    getTextAlign(pro.style)
                  "
                >
                  <div
                    class="mb-1"
                    v-if="pro.showLabel"
                    :style="leadLabelStyle(pro.style)"
                  >
                    <p class="m-0 p-0 text-left">
                      {{ pro.labelText }}
                      <span v-if="pro.isRequired" style="color: red">*</span>
                    </p>
                  </div>
                  <div
                    class=" "
                    :style="
                      pro.style.borderType === 'border'
                        ? getInputBorderCSS(pro.style)
                        : getInputBorderBottomCSS(pro.style)
                    "
                  >
                    <textarea
                      type="text"
                      :placeholder="pro.InfoTypePlaceholder"
                      :required="pro.isRequired"
                      v-model="pro.LeadTextValue"
                      rows="1"
                      :style="freeTextInputStyles(pro.style)"
                      id="textInput"
                      style="resize: none; overflow: auto"
                      class="text-input p-1"
                      @input="handleFreeTextInputChange(pro)"
                      @keyup="InputAdjust"
                      @focus="InputAdjust"
                      autofocus
                       @keyup.enter="initiateSaveLeadData"
                    />
                  </div>

                  <!-- Limit Characters Div start-->
                  <!-- <div v-if="pro.limitFreeTextArea.limitFreeTextAreaValue" class="text-right pr-2"> -->
                  <div
                    v-if="
                      Object.prototype.hasOwnProperty.call(
                        pro,
                        `limitFreeTextArea`
                      ) && pro.limitFreeTextArea.limitFreeTextAreaValue
                    "
                    class="text-right pr-2"
                  >
                    <span
                      v-if="pro.limitFreeTextArea.limitSelectionType == 'max'"
                      :style="`color:${pro.style.color};opacity:${pro.style.textOpacity};`"
                      >{{ pro.LeadTextValue.length }}/{{
                        pro.limitFreeTextArea.maxFreeTextAreaValue
                      }}</span
                    >
                    <span
                      v-if="pro.limitFreeTextArea.limitSelectionType == 'min'"
                      :style="`color:${pro.style.color};opacity:${pro.style.textOpacity};`"
                      >{{ pro.limitFreeTextArea.minFreeTextAreaValue }}/{{
                        pro.LeadTextValue.length
                      }}</span
                    >
                    <span
                      v-if="pro.limitFreeTextArea.limitSelectionType == 'exact'"
                      :style="`color:${pro.style.color};opacity:${pro.style.textOpacity};`"
                      >{{ pro.LeadTextValue.length }}/{{
                        pro.limitFreeTextArea.exactFreeTextAreaValue
                      }}</span
                    >
                  </div>
                  <!-- Limit Characters Div end-->
                </div>
              </div>

              <div v-if="pro.type == 'address' && !showAuthBlock">
                <div
                  :style="
                    getMarginCss(pro.style, isDesktopView) +
                    getTextAlign(pro.style)
                  "
                >
                  <div v-if="pro.showAddressLine1">
                    <div class="mb-1" v-if="pro.showAddressLine1Label">
                      <p class="m-0 p-0" :style="leadLabelStyle(pro.style)">
                        <span
                          v-if="
                            pro.isAddressLine1Required &&
                            pro.style.textInputAlign == 'end'
                          "
                          style="color: red"
                          >*</span
                        >
                        {{ pro.AddressLine1Label }}
                        <span
                          v-if="
                            pro.isAddressLine1Required &&
                            (pro.style.textInputAlign == 'center' ||
                              pro.style.textInputAlign == 'left')
                          "
                          style="color: red"
                          >*</span
                        >
                      </p>
                    </div>
                    <div
                      :style="
                        pro.style.borderType === 'border'
                          ? getInputBorderCSS(pro.style)
                          : getInputBorderBottomCSS(pro.style)
                      "
                    >
                      <input
                        type="text"
                        :placeholder="pro.AddressLine1Placeholder"
                        :required="pro.isAddressLine1Required"
                        v-model="pro.AddressLine1Input"
                        class="text-dark font-weight-medium input-element CustomTextInput"
                        :style="customInputStyles(pro.style)"
                        autofocus
                      />
                    </div>
                  </div>
                  <div v-if="pro.showAddressLine2" class="my-2">
                    <div class="mb-1" v-if="pro.showAddressLine2Label">
                      <p class="m-0 p-0" :style="leadLabelStyle(pro.style)">
                        <span
                          v-if="
                            pro.isAddressLine2Required &&
                            pro.style.textInputAlign == 'end'
                          "
                          style="color: red"
                          >*</span
                        >
                        {{ pro.AddressLine2Label }}
                        <span
                          v-if="
                            pro.isAddressLine2Required &&
                            (pro.style.textInputAlign == 'center' ||
                              pro.style.textInputAlign == 'left')
                          "
                          style="color: red"
                          >*</span
                        >
                      </p>
                    </div>
                    <div
                      :style="
                        pro.style.borderType === 'border'
                          ? getInputBorderCSS(pro.style)
                          : getInputBorderBottomCSS(pro.style)
                      "
                    >
                      <input
                        type="text"
                        :placeholder="pro.AddressLine2Placeholder"
                        :required="pro.isAddressLine2Required"
                        v-model="pro.AddressLine2Input"
                        :style="customInputStyles(pro.style)"
                        class="text-dark font-weight-medium input-element CustomTextInput"
                        autofocus
                      />
                    </div>
                  </div>
                  <div v-if="pro.showZipCode" class="my-2">
                    <div class="mb-1" v-if="pro.showZipCodeLabel">
                      <p class="m-0 p-0" :style="leadLabelStyle(pro.style)">
                        <span
                          v-if="
                            pro.isZipCodeRequired &&
                            pro.style.textInputAlign == 'end'
                          "
                          style="color: red"
                          >*</span
                        >
                        {{ pro.ZipCodeLabel }}
                        <span
                          v-if="
                            pro.isZipCodeRequired &&
                            (pro.style.textInputAlign == 'center' ||
                              pro.style.textInputAlign == 'left')
                          "
                          style="color: red"
                          >*</span
                        >
                      </p>
                    </div>
                    <div
                      :style="
                        pro.style.borderType === 'border'
                          ? getInputBorderCSS(pro.style)
                          : getInputBorderBottomCSS(pro.style)
                      "
                    >
                      <input
                        type="text"
                        :placeholder="pro.ZipCodePlaceholder"
                        :required="pro.isZipCodeRequired"
                        v-model="pro.ZipCodeInput"
                        :style="customInputStyles(pro.style)"
                        class="text-dark font-weight-medium input-element CustomTextInput"
                        autofocus
                      />
                    </div>
                  </div>
                  <div v-if="pro.showCity" class="my-2">
                    <div
                      class="mb-1 d-flex align-items-center"
                      v-if="pro.showCityLabel"
                    >
                      <p class="m-0 p-0" :style="leadLabelStyle(pro.style)">
                        <span
                          v-if="
                            pro.isCityRequired &&
                            pro.style.textInputAlign == 'end'
                          "
                          style="color: red"
                          >*</span
                        >
                        {{ pro.CityLabel }}
                        <span
                          v-if="
                            pro.isCityRequired &&
                            (pro.style.textInputAlign == 'center' ||
                              pro.style.textInputAlign == 'left')
                          "
                          style="color: red"
                          >*</span
                        >
                      </p>
                      <div
                        v-if="CityLoading"
                        :style="`color:${pro.style.color};`"
                        class="spinner-border spinner-border-sm ml-2"
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                    <div
                      :style="
                        pro.style.borderType === 'border'
                          ? getInputBorderCSS(pro.style)
                          : getInputBorderBottomCSS(pro.style)
                      "
                      class="select-div h-100"
                    >
                      <!-- <input
                        type="text"
                        :placeholder="pro.CityPlaceholder"
                        :required="pro.isCityRequired"
                        v-model="pro.CityInput"
                        :style="customInputStyles(pro.style)"
                        class="text-dark font-weight-medium input-element CustomTextInput"
                      /> -->
                      <multiselect
                        :options="getAllCities"
                        :multiple="false"
                        class="multiSelect p-0"
                        :placeholder="pro.CityPlaceholder"
                        :required="pro.isCityRequired"
                        v-model="pro.CityInput"
                        :style="
                          QuestionPageStyle(pro.style) +
                          getFontStyle(pro.style) +
                          customInputStyles(pro.style)
                        "
                        :close-on-select="true"
                        :searchable="true"
                      >
                      </multiselect>
                    </div>
                  </div>
                  <div v-if="pro.showState" class="my-2">
                    <div
                      class="mb-1 d-flex align-items-center"
                      v-if="pro.showStateLabel"
                    >
                      <p class="m-0 p-0" :style="leadLabelStyle(pro.style)">
                        <span
                          v-if="
                            pro.isStateRequired &&
                            pro.style.textInputAlign == 'end'
                          "
                          style="color: red"
                          >*</span
                        >
                        {{ pro.StateLabel }}
                        <span
                          v-if="
                            pro.isStateRequired &&
                            (pro.style.textInputAlign == 'center' ||
                              pro.style.textInputAlign == 'left')
                          "
                          style="color: red"
                          >*</span
                        >
                      </p>
                      <div
                        v-if="StateLoading"
                        :style="`color:${pro.style.color};`"
                        class="spinner-border spinner-border-sm ml-2"
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                    <div
                      :style="
                        pro.style.borderType === 'border'
                          ? getInputBorderCSS(pro.style)
                          : getInputBorderBottomCSS(pro.style)
                      "
                      class="select-div h-100"
                    >
                      <multiselect
                        :options="getAllStates"
                        :multiple="false"
                        class="multiSelect p-0"
                        :placeholder="pro.StatePlaceholder"
                        :required="pro.isStateRequired"
                        v-model="pro.StateInput"
                        :style="
                          QuestionPageStyle(pro.style) +
                          getFontStyle(pro.style) +
                          customInputStyles(pro.style)
                        "
                        :close-on-select="true"
                        :searchable="true"
                      >
                      </multiselect>
                    </div>
                  </div>

                  <div v-if="pro.showCountry" class="my-2">
                    <div
                      class="mb-1 d-flex align-items-center"
                      v-if="pro.showCountryLabel"
                    >
                      <p class="m-0 p-0" :style="leadLabelStyle(pro.style)">
                        <span
                          v-if="
                            pro.isCountryRequired &&
                            pro.style.textInputAlign == 'end'
                          "
                          style="color: red"
                          >*</span
                        >
                        {{ pro.CountryLabel }}
                        <span
                          v-if="
                            pro.isCountryRequired &&
                            (pro.style.textInputAlign == 'center' ||
                              pro.style.textInputAlign == 'left')
                          "
                          style="color: red"
                          >*</span
                        >
                      </p>
                      <div
                        v-if="CountryLoading"
                        :style="`color:${pro.style.color};`"
                        class="spinner-border spinner-border-sm ml-2"
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                    <div
                      :style="
                        pro.style.borderType === 'border'
                          ? getInputBorderCSS(pro.style)
                          : getInputBorderBottomCSS(pro.style)
                      "
                      class="select-div h-100"
                    >
                      <multiselect
                        :options="getAllCountries"
                        :multiple="false"
                        class="multiSelect p-0"
                        :placeholder="pro.CountryPlaceholder"
                        v-model="pro.CountryInput"
                        :required="pro.isCountryRequired"
                        :style="
                          QuestionPageStyle(pro.style) +
                          getFontStyle(pro.style) +
                          customInputStyles(pro.style)
                        "
                        :close-on-select="true"
                        :searchable="true"
                      >
                      </multiselect>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Auth -->
              <div
                v-if="
                  pro.type == 'Auth' &&
                  isBothEmailAndOTPpresent &&
                  verifyOtpView &&
                  getOTPBlock &&
                  showAuthBlock
                "
              >
                <div :style="getMarginCss(pro.style, isDesktopView)">
                  <div class="mb-1" v-if="pro.showLabel">
                    <p class="" :style="verifyOtpTextStyles(pro.style)">
                      {{ pro.VerifyOTPText }}
                    </p>
                  </div>

                  <div v-if="verifyOtpView">
                    <AuthOtpInputComp
                      :pro="pro"
                      class="mb-3"
                      @updateOtpValue="updateOtpInputValue($event)"
                    />
                    <div
                      class="d-flex"
                      :style="`justify-content:${pro.style.sendBtnPosition};`"
                    >
                      <div class="d-flex flex-column">
                        <button
                          class="btn"
                          @click="navigateOtpMethods(`verifyOtp`)"
                          :disabled="!isVerifyOTPDisable"
                          :style="authButtonStyles(pro.style)"
                        >
                          <!-- {{ pro.verifyAuthText }} -->
                          <span v-if="!verifyOTPSpinner">{{
                            pro.verifyAuthText
                          }}</span>
                          <div
                            v-else
                            class="spinner-border spinner-border-sm"
                            role="status"
                          >
                            <span class="sr-only">Loading...</span>
                          </div>
                        </button>

                        <button
                          @click="resetEmailOtp"
                          class="btn pb-0"
                          style="font-size: 14px"
                          :style="`color:${pro.style.strokeColor}`"
                        >
                          <BIconArrowLeft class="mr-2" />
                          {{ pro.goBackBtnText }}
                        </button>
                        <button
                          @click="navigateOtpMethods(`resendOtp`)"
                          :disabled="resendOTPSpinner"
                          class="btn p-0"
                          style="font-size: 14px"
                          :style="`color:${pro.style.strokeColor}`"
                        >
                          {{ pro.resendOtpText }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Buttons -->

              <div
                v-if="pro.type == 'tooltip'"
                :style="`display:flex;justify-content: ${pro.style.position} ; `"
              >
                <button
                  :style="
                    getButtonCss(pro.style, isDesktopView) +
                    getCircularBtn(pro.isBtnRounded)
                  "
                  @mouseenter="pro.isTooltipVisible = true"
                  @mouseleave="pro.isTooltipVisible = false"
                >
                  <span v-if="pro.useIcon">
                    <BIcon :icon="pro.selectedIcon" />
                  </span>

                  <span
                    v-else
                    :style="{
                      opacity: `${pro.style.textOpacity}%`,
                      fontSize: `${pro.style.fontSize}px`,
                    }"
                  >
                    {{ pro.text }}
                  </span>
                </button>
                <ToolTipComp
                  v-if="pro.isTooltipVisible"
                  :tooltipData="pro"
                  :isDesktopView="isDesktopView"
                />
              </div>

              <div v-if="pro.type == 'fileUpload'">
                <div
                  :style="
                    getMarginCss(pro.style, isDesktopView) +
                    getTextAlign(pro.style)
                  "
                >
                  <FilesUploadComp
                    :pro="pro"
                    :isDesktopView="isDesktopView"
                    :apiToken="apiToken"
                    @updateUploadedFiles="UpdateFiles($event)"
                  />
                </div>
              </div>
              <div v-if="pro.type == 'imageComp'">
                <InlineImageComp :pro="pro" :isDesktopView="isDesktopView" />
              </div>

              <!-- Skip Btn Start -->
              <div
                v-if="pro.type == 'skipButton'"
                :style="`display:flex;justify-content: ${pro.style.position} ; `"
              >
                <button
                  :style="getButtonCss(pro.style, isDesktopView)"
                  @click="skipLead"
                >
                  <span
                    :style="{
                      opacity: `${pro.style.textOpacity}%`,
                      fontSize: `${pro.style.fontSize}px`,
                    }"
                  >
                    {{ pro.text }}
                  </span>
                  {{ pro.style.alignItems }}
                </button>
              </div>
              <!-- Skip Btn End -->
            </div>
          </div>
        </div>

        <!-- LEad Ans Back Button -->
        <div v-if="!showAuthBlock">
          <div
            :style="getHeightWidth(StaticButtonElement.style)"
            class="content"
          >
            <div class="w-100">
              <div
                class="d-flex"
                :style="
                  getBackNextButtonPosition(
                    StaticButtonElement,
                    CurrentPage.value
                  )
                "
              >
                <button
                  style="z-index: 1000"
                  :style="
                    getCommonButtonCSS(
                      StaticButtonElement.style,
                      isDesktopView
                    ) +
                    getBackNextButtonCss(
                      StaticButtonElement.content.backButton.style,
                      isDesktopView
                    )
                  "
                  @click="backPage"
                  v-show="
                    !StaticButtonElement.content.backButton.hideBackButton
                  "
                  class="ButtonHover"
                >
                  <span
                    :style="{
                      opacity: `${StaticButtonElement.content.backButton.style.textOpacity}%`,
                      fontSize: `${StaticButtonElement.content.backButton.style.fontSize}px`,
                    }"
                    >{{ StaticButtonElement.content.backButton.text }}</span
                  >
                </button>
                <button
                  :disabled="isLoadingQuestion || nextBtnSpinner"
                  style="z-index: 1000"
                  :style="
                    getCommonButtonCSS(
                      StaticButtonElement.style,
                      isDesktopView
                    ) +
                    getBackNextButtonCss(
                      StaticButtonElement.content.nextButton.style,
                      isDesktopView
                    )
                  "
                  @click="initiateSaveLeadData()"
                  class="ButtonHover"
                >
                  <b-spinner
                    v-if="isLoadingQuestion"
                    small
                    label="Small Spinner"
                  ></b-spinner>
                  <span
                    v-if="!nextBtnSpinner"
                    :style="{
                      opacity: `${StaticButtonElement.content.nextButton.style.textOpacity}%`,
                      fontSize: `${StaticButtonElement.content.nextButton.style.fontSize}px`,
                    }"
                    >{{ StaticButtonElement.content.nextButton.text }}</span
                  >
                  <div
                    v-else
                    class="spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Image Align Right -->
    <div
      class="px-0"
      v-show="showRightImage"
      :class="[
        {
          'col-12 col-lg-12 col-xs-12': !isDesktopView,
          'image-division col-6': isDesktopView,
        },
      ]"
      :style="
        calculateImageWidth(
          pageDesign.imageBlock,
          isDesktopView,
          IsImageVariableWidthActive
        )
      "
    >
      <div :class="isDesktopView ? 'image-division' : ''">
        <div :style="height100(isDesktopView)">
          <LazyImage
            :key="getImageSrc(pageDesign.imageBlock, isDesktopView)"
            :style="
              getUniversalImageStyle(
                isDesktopView,
                pageDesign.imageBlock.style,
                IsImageVariableWidthActive,
                pageDesign.imageBlock
              ) +
              getImageRotation(pageDesign.imageBlock.style) +
              getImageOpacity(pageDesign.imageBlock.style)
            "
            :class="[
              `pic-${getSelectedPage.order}`,
              getImageSrc(pageDesign.imageBlock, isDesktopView) ==
              '/images/default_start_page.png'
                ? 'default-bg'
                : '',
            ]"
            :src="MainImgSrc(getImageSrc(pageDesign.imageBlock, isDesktopView))"
            alt
          />
        </div>
      </div>
    </div>

    <!-- Show Logo Footer -->

    <!-- End -->
  </div>
</template>
<script>
import { getFormatedUTCTimeStamp } from "../../composibles/getUTCTimeStamp";
import Vue from "vue";
import VS2 from "vue-script2";
Vue.use(VS2);
import cssFunctionMixin from "../customize/mixin/cssFunction.js";
import axios from "axios";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { BIconArrowLeft } from "bootstrap-vue";
import Translate from "../customize/mixin/TranslationMixin.js";
import Multiselect from "vue-multiselect";
import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
export default {
  props: [
    "isDesktopView",
    "apiToken",
    "isTest",
    "animationType",
    "RoutePage",
    "getIsAnimationEnabled",
    "getAnimationSpeedinMillis",
  ],

  mixins: [cssFunctionMixin, Translate],
  components: {
    BIconArrowLeft,
    VueTelInput,
    Multiselect,
    AuthOtpInputComp: () =>
      import(
        /* webpackChunkName: "AuthOtpInputComp" */ "./AuthOtpInputComp.vue"
      ),
    PreviewProgressBar: () =>
      import(
        /* webpackChunkName: "PreviewProgressBar" */ "./PreviewProgressBar.vue"
      ),
    ToolTipComp: () =>
      import(/* webpackChunkName: "ToolTipComp" */ "./ToolTipComp.vue"),
    FilesUploadComp: () =>
      import(/* webpackChunkName: "FilesUploadComp" */ "./FileUploadComp.vue"),
    HtmlElement: () =>
      import(/* webpackChunkName: "HtmlElement" */ "./HtmlElement.vue"),

    InlineImageComp: () =>
      import(
        /* webpackChunkName: "InlineImageComp" */ "./components/InlineImageComp.vue"
      ),
  },
  data() {
    return {
      isUpPromote: false,
      activeSideBar: "pageStyle",
      count: 0,
      pageDesign: {},
      alertMsg: "",
      isEmailValid: true,
      isPhoneValid: true,
      isFreeTextAreaValid: true,
      FreeTextAreaErrorText: "",
      showAlert: false,
      // leadTextValue: "",
      dateInputValue: null,
      monthInputValue: null,
      yearInputValue: null,
      datePickerValue: null,
      dateCustomInputValue: null,
      monthCustomInputValue: null,
      yearCustomInputValue: null,
      datePickerCustomValue: null,
      email: "",
      name: "",
      phone: "",
      date: "",
      website: "",
      organisation: "",
      address_1: "",
      address_2: "",
      city: "",
      zip_code: "",
      state: "",
      country: "",
      OTPValue: null,
      OTPToken: null,
      verifyOTPSpinner: false,
      verifyOtpView: false,
      nextBtnSpinner: false,
      resendOTPSpinner: false,
      alreadyRecordStatusView: false,
      isUpRemoteAccount: false,
      otpVerified: false,
      ShowOtpIcon: false,
      delayIncrement: 0.2, // The delay between each item
      //  allowedAuthUser:['1386','2492'],

      dropdown: {
        showSearchBox: true,
        showFlags: true,
        width: "390px",
      },

      AllCountriesData: [],
      AllStatesData: [],
      AllCitiesData: [],
      CountryLoading: false,
      StateLoading: false,
      CityLoading: false,
    };
  },
  methods: {
    AOSAnimationDelay(index) {
      const baseDelay = parseInt(this.getAnimationSpeedinMillis) || 200;
      return this.getIsAnimationEnabled ? (index + 1) * baseDelay : 50;
    },
    async getCountryStateCityData(link) {
      const data = await axios.get(link);

      return data;
    },
    async importCountryModule() {
      let url = process.env.VUE_APP_COUNTRY_DATA_LINK;
      try {
        this.CountryLoading = true;

        let response = await this.getCountryStateCityData(url);
        if (response.status == 200) {
          this.AllCountriesData = response.data;
        }
      } catch (error) {
        if (error) {
          this.$toasted.show(`Error occured while fetching Country data`, {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
      } finally {
        this.CountryLoading = false;
      }
    },
    async importCityModule() {
      let url = process.env.VUE_APP_CITY_DATA_LINK;
      try {
        this.CityLoading = true;

        let response = await this.getCountryStateCityData(url);
        if (response.status == 200) {
          this.AllCitiesData = response.data;
        }
      } catch (error) {
        if (error) {
          this.$toasted.show(`Error occured while fetching City data`, {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
      } finally {
        this.CityLoading = false;
      }
    },
    async importStateModule() {
      let url = process.env.VUE_APP_STATE_DATA_LINK;
      try {
        this.StateLoading = true;

        let response = await this.getCountryStateCityData(url);
        if (response.status == 200) {
          this.AllStatesData = response.data;
        }
      } catch (error) {
        if (error) {
          this.$toasted.show(`Error occured while fetching State data`, {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
      } finally {
        this.StateLoading = false;
      }
    },
    multiSelectCustomAddressLabel(option) {
      return option.name;
    },
    handleFreeTextInputChange(block) {
      if (Object.prototype.hasOwnProperty.call(block, "limitFreeTextArea")) {
        const { LeadTextValue, limitFreeTextArea } = block;
        const {
          limitSelectionType,
          exactFreeTextAreaValue,
          maxFreeTextAreaValue,
          limitFreeTextAreaValue,
        } = limitFreeTextArea;

        if (limitFreeTextAreaValue) {
          if (
            limitSelectionType === "max" &&
            LeadTextValue.length > maxFreeTextAreaValue
          ) {
            block.LeadTextValue = LeadTextValue.slice(0, maxFreeTextAreaValue);
          } else if (
            limitSelectionType === "exact" &&
            LeadTextValue.length > exactFreeTextAreaValue
          ) {
            block.LeadTextValue = LeadTextValue.slice(
              0,
              exactFreeTextAreaValue
            );
          }
        }
      }
    },
    handleCustomTextAreaInputChange(block) {
      if (Object.prototype.hasOwnProperty.call(block, "limitFreeTextArea")) {
        const { limitFreeTextArea } = block;
        const { fieldValue } = block.selectedInfoType;
        const {
          limitSelectionType,
          exactFreeTextAreaValue,
          maxFreeTextAreaValue,
          limitFreeTextAreaValue,
        } = limitFreeTextArea;

        if (limitFreeTextAreaValue) {
          if (
            limitSelectionType === "max" &&
            fieldValue.length > maxFreeTextAreaValue
          ) {
            block.selectedInfoType.fieldValue = fieldValue.slice(
              0,
              maxFreeTextAreaValue
            );
          } else if (
            limitSelectionType === "exact" &&
            fieldValue.length > exactFreeTextAreaValue
          ) {
            block.selectedInfoType.fieldValue = fieldValue.slice(
              0,
              exactFreeTextAreaValue
            );
          }
        }
      }
    },
    removeSpecialCharacter(email) {
      const index = this.pageDesign.blocksArray.findIndex(
        (block) =>
          block.type == "freeText" &&
          block.selectedInfoType.fieldName == "email"
      );

      if (index > -1) {
        this.pageDesign.blocksArray[index].selectedInfoType.fieldValue = email;
      }
    },

    translateTextToItalian(key) {
      return this.translateToItalian(key, this.getPreviewSettings.quizId);
    },

    elthInitiateOtp(type) {
      let allowedTypes = ["email", "number", "text"];

      if (allowedTypes.includes(type)) {
        this.initiateSaveLeadData();
      }
    },

    resetEmailOtp() {
      if (localStorage.getItem("already_record_status")) {
        localStorage.removeItem("already_record_status");
      }
      this.verifyOtpView = false;
      this.OTPToken = null;
      this.isUpRemoteAccount = false;
    },
    continueEltQuiz() {
      let InputEmail = this.isEmailInput;

      if (InputEmail) {
        localStorage.setItem("already_record_email", InputEmail);
        localStorage.setItem("already_record_quiz_continue", true);
      }
      this.submitLeadPageData();
    },
    goToElthCollectionResult() {
      let InputEmail = this.isEmailInput;

      if (InputEmail) {
        localStorage.setItem("already_record_email", InputEmail);
        const JumpToPreviewPageIndex = this.getTotalPreviewPages.findIndex(
          (x) => x.value == "resultPage"
        );
        this.jumpToPreviewPage(JumpToPreviewPageIndex);
      }
    },
    openCalender() {
      // this.$refs.CustomDateInputRef.current.focus()
      // this.$refs.CustomDateInputRef.showPicker();
    },

    InputAdjust(e) {
      let Element = e.target;
      // Element.rows = 2;
      Element.rows = 1;
      const styles = window.getComputedStyle(Element);
      const paddingTop = parseInt(styles.paddingTop);
      const paddingBottom = parseInt(styles.paddingBottom);
      const padding = paddingTop + paddingBottom;
      const initialHeight = (parseInt(styles.height) - padding) / Element.rows;
      const scrollHeight = Element.scrollHeight - padding;
      const newRows = Math.ceil(scrollHeight / initialHeight);
      Element.rows = newRows - 1 == 0 ? 1 : newRows - 1;
    },
    backPage() {
      this.backPageAnimation();
      this.goToBackPreviewPage();
    },
    ...mapActions([
      "goToNextPreviewPage",
      "goToBackPreviewPage",
      "populateTrackCounter",
      "updateTrackCounter",
    ]),
    ...mapMutations([
      "updateLeadCounter",
      "setSavedLeadID",
      "nextPageAnimation",
      "backPageAnimation",
      "jumpToPreviewPage",
      "updateSavedLeads",
    ]),
    getPageDesign() {
      let design =
        "text-align:" +
        this.pageDesign.pageStyles.textAlign +
        ";color:" +
        this.pageDesign.pageStyles.color +
        "; background:" +
        this.pageDesign.pageStyles.backgroundColor +
        "; width:" +
        this.pageDesign.pageStyles.width +
        "px; " +
        this.getQuizPageHeight(
          this.pageDesign.pageStyles.height,
          this.RoutePage,
          this.isTest
        );

      return design;
    },

    getInputBorderCSS(property) {
      return (
        "border:" +
        property.borderSize +
        "px solid " +
        property.strokeColor +
        "; border-radius:" +
        property.borderRadius +
        "px; " +
        "transform: rotate(-" +
        property.strokeRotation +
        "deg);"
      );
    },
    getInputBorderBottomCSS(property) {
      return (
        "border-bottom:" +
        property.borderSize +
        "px solid " +
        property.strokeColor +
        "; border-radius:" +
        property.borderRadius +
        "px; " +
        "transform: rotate(-" +
        property.strokeRotation +
        "deg);"
      );
    },
    getcheckboxRotate(property) {
      return `  transform: rotate(-${
        property.strokeRotation || 0
      }deg);--selectedCheckboxColor : ${property?.color || "#000000"};`;
    },
    getLeadPageValues() {
      let getStartedDesignValues = {};
      if (this.getTotalPages && this.getSelectedPage) {
        getStartedDesignValues = this.getSelectedPage.pageDesign;
      }
      return getStartedDesignValues;
    },
    goToURLClicked(url) {
      let URL = url;
      let pattern = /^((http|https|ftp):\/\/)/;
      if (URL !== "") {
        if (!pattern.test(URL)) {
          URL = "https://" + URL;
        }
        window.open(URL);
      }
    },
    getValidatedValues() {
      const requiredValueBlocks = [];
      const notRequiredValueBlocks = [];
      // let isCheckboxChecked;
      let validationArr = [];

      this.pageDesign.blocksArray.forEach((block) => {
        if (block.type == "freeText") {
          if (block.isRequired == true) {
            // requiredValueBlocks.push(block.selectedInfoType);
            requiredValueBlocks.push(block);
          } else {
            // notRequiredValueBlocks.push(block.selectedInfoType);
            notRequiredValueBlocks.push(block);
          }
        }
        if (block.type == "customInput") {
          if (block.isRequired == true) {
            requiredValueBlocks.push(block);
          } else {
            notRequiredValueBlocks.push(block);
          }

          // Custom Text Area Validation start
          if (
            Object.prototype.hasOwnProperty.call(block, "limitFreeTextArea")
          ) {
            const { limitFreeTextArea } = block;
            const { fieldValue } = block.selectedInfoType;
            const {
              limitSelectionType,
              exactFreeTextAreaValue,
              minFreeTextAreaValue,
              limitFreeTextAreaValue,
            } = limitFreeTextArea;

            if (limitFreeTextAreaValue) {
              // Min Condition not satisfied

              if (
                limitSelectionType === "min" &&
                fieldValue.length < minFreeTextAreaValue
              ) {
                this.isFreeTextAreaValid = false;
                validationArr.push(false);
                this.FreeTextAreaErrorText = this.translateText(
                  "Text too short. Please add more content."
                );
              }

              // Exact Length not satisfied
              if (
                limitSelectionType === "exact" &&
                fieldValue.length != exactFreeTextAreaValue
              ) {
                this.isFreeTextAreaValid = false;
                validationArr.push(false);
                this.FreeTextAreaErrorText = this.translateText(
                  "Text must meet the exact length."
                );
              }
            }
          }
          // Custom Text Area Validation End
        }
        if (block.type == "freeTextArea") {
          if (block.isRequired == true) {
            requiredValueBlocks.push(block);
          } else {
            notRequiredValueBlocks.push(block);
          }

          // Check for min max exact conditions

          if (
            Object.prototype.hasOwnProperty.call(block, "limitFreeTextArea")
          ) {
            const { LeadTextValue, limitFreeTextArea } = block;
            const {
              limitSelectionType,
              exactFreeTextAreaValue,
              minFreeTextAreaValue,
              limitFreeTextAreaValue,
            } = limitFreeTextArea;

            if (limitFreeTextAreaValue) {
              // Min Condition not satisfied

              if (
                limitSelectionType === "min" &&
                LeadTextValue.length < minFreeTextAreaValue
              ) {
                this.isFreeTextAreaValid = false;
                validationArr.push(false);
                this.FreeTextAreaErrorText = this.translateText(
                  "Text too short. Please add more content."
                );
              }

              // Exact Length not satisfied
              if (
                limitSelectionType === "exact" &&
                LeadTextValue.length != exactFreeTextAreaValue
              ) {
                this.isFreeTextAreaValid = false;
                validationArr.push(false);
                this.FreeTextAreaErrorText = this.translateText(
                  "Text must meet the exact length."
                );
              }
            }
          }
        }

        if (block.type == "checkbox") {
          // requiredValueBlocks.push(block);
          if (Object.prototype.hasOwnProperty.call(block, "isRequired")) {
            if (block.isRequired == true) {
              requiredValueBlocks.push(block);
            } else {
              notRequiredValueBlocks.push(block);
            }
          } else {
            requiredValueBlocks.push(block);
          }
        }
        if (block.type == "address") {
          let addressObject = {
            fieldName: "address",
            fieldValue: {},
            text: "address",
            type: "address",
          };

          if (block.isAddressLine1Required == true) {
            addressObject.fieldValue["AddressLine1Input"] =
              block.AddressLine1Input;
          }
          if (block.isAddressLine2Required == true) {
            addressObject.fieldValue["AddressLine2Input"] =
              block.AddressLine2Input;
          }
          if (block.isCityRequired == true) {
            addressObject.fieldValue["CityInput"] = block.CityInput;
          }
          if (block.isCountryRequired == true) {
            addressObject.fieldValue["CountryInput"] = block.CountryInput;
          }
          if (block.isStateRequired == true) {
            addressObject.fieldValue["StateInput"] = block.StateInput;
          }
          if (block.isZipCodeRequired == true) {
            addressObject.fieldValue["ZipCodeInput"] = block.ZipCodeInput;
          }

          requiredValueBlocks.push(addressObject);
        }
        if (block.type == "fileUpload") {
          let fileUploadObj = {
            fieldName: "fileUpload",
            fieldValue: [],
            text: "fileUpload",
            type: "fileUpload",
          };
          if (block.isRequired == true) {
            fileUploadObj.fieldValue = block.uploadedFiles;
            requiredValueBlocks.push(fileUploadObj);
          } else {
            notRequiredValueBlocks.push(block);
          }
        }
      });
      const emailInput = this.isEmailInput;
      if (emailInput) {
        if (!this.validateEmail(emailInput)) {
          this.isEmailValid = false;
        }
        validationArr.push(this.validateEmail(emailInput));
      }

      const phoneInput = this.isPhoneNumberValid;

      if (phoneInput && this.$refs.telInput[0]?.phoneObject) {
        const { valid, nationalNumber } = this.$refs.telInput[0]?.phoneObject;

        if (this.pageDesign.blocksArray[phoneInput].isRequired) {
          this.isPhoneValid = valid;
          validationArr.push(valid);
        } else {
          if (nationalNumber && !valid) {
            this.isPhoneValid = valid;
            validationArr.push(valid);
          }
        }
      }

      const allRequiredValues = requiredValueBlocks.every((block) => {
        if (
          block.type == "freeText" &&
          block.selectedInfoType.fieldName == "date"
        ) {
          if (
            block.selectedDateFormat == "MMDD" ||
            block.selectedDateFormat == "DDMM"
          ) {
            return (
              block.selectedInfoType.monthValue !== "" &&
              block.selectedInfoType.dateValue !== ""
            );
          } else {
            return (
              block.selectedInfoType.yearValue !== "" &&
              block.selectedInfoType.monthValue !== "" &&
              block.selectedInfoType.dateValue !== ""
            );
          }
        } else if (
          block.type == "freeText" &&
          block.selectedInfoType.fieldName !== "date"
        ) {
          return block.selectedInfoType.fieldValue !== "";
        } else if (block.type == "checkbox") {
          return block.value;
        } else if (block.type == "address") {
          for (const key in block.fieldValue) {
            // if (block.fieldValue.hasOwnProperty(key)) {
            if (Object.prototype.hasOwnProperty.call(block.fieldValue, key)) {
              const value = block.fieldValue[key];
              if (typeof value !== "string" || value.trim() === "") {
                return false;
              }
            }
          }
          return true;
        } else if (block.type == "fileUpload") {
          return block.fieldValue.length > 0;
        } else if (block.type == "customInput") {
          return block.selectedInfoType.fieldValue !== "";
        } else {
          // return this.leadTextValue !== "";
          return block.LeadTextValue !== "";
        }
      });

      validationArr.push(allRequiredValues);

      return validationArr.every((x) => x);
    },
    async initiateSaveLeadData() {
      if (this.getValidatedValues() || this.isEmailContainSpecialCharacter) {
        let isOTPEnabled = this.isBothEmailAndOTPpresent;
        if (isOTPEnabled && !this.isEmailContainSpecialCharacter) {
          // this.SendOTP();
          this.navigateOtpMethods(`SendOTP`);
        } else {
          if (
            this.isEmailContainSpecialCharacter &&
            this.isBaldenOrElthV3Client.includes(this.getPreviewSettings.quizId)
          ) {
            localStorage.setItem("already_record_status", true);

            this.alreadyRecordStatusView = true;
            let emailWithoutSpecialChar = this.isEmailInput.replaceAll("%", "");
            this.removeSpecialCharacter(emailWithoutSpecialChar);
            await this.goToElthCollectionResult();
            return;
          }
          this.submitLeadPageData();
        }
      } else {
        if (!this.isEmailValid) {
          this.alertMsg = this.translateText("Enter a valid email address.");
        } else if (!this.isPhoneValid) {
          this.alertMsg = this.translateText("Enter a valid phone number.");
        } else if (!this.isFreeTextAreaValid) {
          this.alertMsg = this.FreeTextAreaErrorText;
          this.FreeTextAreaErrorText = "";
        } else {
          this.alertMsg = this.translateText(
            "All required fields must be filled."
          );
        }

        this.showAlert = true;
        setTimeout(
          function () {
            this.showAlert = false;
            this.alertMsg = "";
          }.bind(this),
          3000
        );
        this.isEmailValid = true;
        this.isPhoneValid = true;
        this.isFreeTextAreaValid = true;
        this.isDateInputValid = true;
      }
    },

    async SendOTP() {
      let InputEmail = this.isEmailInput;
      if (InputEmail) {
        try {
          this.nextBtnSpinner = true;
          let data = {
            emailOTP: true,
            email: InputEmail,
            api_token: this.apiToken,
            quizId: this.getPreviewSettings.quizId,
          };
          if (this.isTest) {
            data["isTest"] = true;
          }

          const response = await axios.post(`api/sendOtp`, data);

          if (response.status == 200) {
            this.isUpRemoteAccount = response.data.data.is_up_promote_account;

            if (
              this.isUpRemoteAccount ||
              this.isBaldenOrElthV3Client.includes(
                this.getPreviewSettings.quizId
              )
            ) {
              this.verifyOtpView =
                response.data?.data.otp_token ||
                this.isBaldenOrElthV3Client.includes(
                  this.getPreviewSettings.quizId
                )
                  ? true
                  : false;
              this.alertMsg = this.translateText(
                "Verification code emailed to you."
              );
              this.showAlert = true;
              this.ShowOtpIcon = true;
              setTimeout(
                function () {
                  this.showAlert = false;
                  this.ShowOtpIcon = false;
                  this.alertMsg = "";
                }.bind(this),
                3000
              );
              this.OTPToken = response.data.data.otp_token;
              if (response.data.data.already_record_status) {
                localStorage.setItem("already_record_status", true);
              }
            } else {
              let redirectUrl = "";
              if (this.getPreviewSettings.quizId == 1386) {
                // redirectUrl ='/quiz/Ys1uWW4Vd7Ku4b571qhHMS258300'
                redirectUrl =
                  this.getEnv?.VUE_APP_ELTH_REDIRECT_QUIZKEY ?? null;
              }

              if (redirectUrl !== "" || redirectUrl == null) {
                window.location.href = window.location.origin + redirectUrl;
              } else {
                this.$toasted.show(
                  this.translateText("An error occurred. Please try again."),
                  {
                    theme: "toasted-primary",
                    position: "bottom-center",
                    duration: 2000,
                  }
                );
              }
            }
          }
        } catch (error) {
          if (error) {
            this.$toasted.show(
              this.translateText("An error occurred. Please try again."),
              {
                theme: "toasted-primary",
                position: "bottom-center",
                duration: 2000,
              }
            );
          }
          throw error;
        } finally {
          this.nextBtnSpinner = false;
        }
      } else {
        this.alertMsg = this.translateText("Enter a valid email address.");
        this.showAlert = true;
        setTimeout(
          function () {
            this.showAlert = false;
            this.alertMsg = "";
          }.bind(this),
          3000
        );
        this.isEmailValid = true;
      }
    },
    async verifyOtp() {
      try {
        this.verifyOTPSpinner = true;
        let data = {
          otp_token: this.OTPToken,
          otp: this.OTPValue,
          api_token: this.apiToken,
        };

        if (this.isTest) {
          data["isTest"] = true;
        }

        const response = await axios.post(`api/validateOtp`, data);
        if (response.status == 200 && response.data.status == "success") {
          // If client is not Italian Client and is a new user
          if (
            !this.isUpRemoteAccount &&
            !this.isBaldenOrElthV3Client.includes(
              this.getPreviewSettings.quizId
            )
          ) {
            this.otpVerified = true;
            return;
          }
          // If client is Italian Client and is a new user
          if (
            !this.isUpRemoteAccount &&
            this.isBaldenOrElthV3Client.includes(this.getPreviewSettings.quizId)
          ) {
            if (
              this.isBaldenOrElthV3Client.includes(
                this.getPreviewSettings.quizId
              ) &&
              !localStorage.getItem("already_record_status")
            ) {
              await this.submitLeadPageData();
              return;
            }
          }

          if (localStorage.getItem("already_record_status")) {
            if (
              this.isBaldenOrElthV3Client.includes(
                this.getPreviewSettings.quizId
              )
            ) {
              await this.goToElthCollectionResult();
              // this.alreadyRecordStatusView = true;
            }
          } else {
            this.submitLeadPageData();
          }
        } else {
          this.alertMsg = this.translateText(`${response.data.data}`);
          this.showAlert = true;
          setTimeout(
            function () {
              this.showAlert = false;
              this.alertMsg = "";
            }.bind(this),
            3000
          );
        }
      } catch (error) {
        if (error) {
          this.$toasted.show(
            this.translateText("An error occurred. Please try again."),
            {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            }
          );
        }
        throw error;
      } finally {
        this.verifyOTPSpinner = false;
      }
    },
    async resendOtp() {
      try {
        this.resendOTPSpinner = true;
        let data = {
          otp_token: this.OTPToken,
          api_token: this.apiToken,
        };
        if (this.isTest) {
          data["isTest"] = true;
        }

        const response = await axios.post(`api/resendOtp`, data);
        if (response.status == 200 && response.data.status == "success") {
          this.alertMsg = this.translateText("New OTP generated and sent.");
          this.showAlert = true;
          setTimeout(
            function () {
              this.showAlert = false;
              this.alertMsg = "";
            }.bind(this),
            3000
          );
          this.OTPToken = response.data.data;
          this.OTPValue = null;
        } else {
          this.alertMsg = this.translateText(`${response.data.data}`);
          this.showAlert = true;
          setTimeout(
            function () {
              this.showAlert = false;
              this.alertMsg = "";
            }.bind(this),
            3000
          );
        }
      } catch (error) {
        if (error) {
          this.$toasted.show(
            this.translateText("An error occurred. Please try again."),
            {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            }
          );
        }
        throw error;
      } finally {
        this.resendOTPSpinner = false;
      }
    },

    // New Otp Methods

    async authSendOtp() {
      let InputEmail = this.isEmailInput;
      if (InputEmail) {
        try {
          this.nextBtnSpinner = true;
          // let data = {
          //   email: InputEmail,
          //   quizId: this.getPreviewSettings.quizId,
          // };
          let data = {
            emailOTP: true,
            email: InputEmail,
            api_token: this.apiToken,
            quizId: this.getPreviewSettings.quizId,
          };
          if (this.isTest) {
            data["isTest"] = true;
          }

          // const response = await axios.post(
          //   `/quiz/otp-verification/send`,
          //   data
          // );
          const response = await axios.post(`api/sendOtp`, data);
          if (response.status == 200) {
            this.verifyOtpView = response.data?.data.otp_token;
            this.OTPToken = response.data.data.otp_token;
            this.alertMsg = this.translateText(
              "Verification code emailed to you."
            );
            this.showAlert = true;
            this.ShowOtpIcon = true;
            setTimeout(
              function () {
                this.showAlert = false;
                this.ShowOtpIcon = false;
                this.alertMsg = "";
              }.bind(this),
              3000
            );
          }
        } catch (error) {
          if (error) {
            this.$toasted.show(
              this.translateText("An error occurred. Please try again."),
              {
                theme: "toasted-primary",
                position: "bottom-center",
                duration: 2000,
              }
            );
          }
          throw error;
        } finally {
          this.nextBtnSpinner = false;
        }
      } else {
        this.alertMsg = this.translateText("Enter a valid email address.");
        this.showAlert = true;
        setTimeout(
          function () {
            this.showAlert = false;
            this.alertMsg = "";
          }.bind(this),
          3000
        );
        this.isEmailValid = true;
      }
    },
    async authVerifyOtp() {
      // let InputEmail = this.isEmailInput;
      try {
        this.verifyOTPSpinner = true;
        // let data = {
        //   email: InputEmail,
        //   quizId: this.getPreviewSettings.quizId,
        //   opt: this.OTPValue,
        // };
        let data = {
          otp_token: this.OTPToken,
          otp: this.OTPValue,
          api_token: this.apiToken,
        };

        if (this.isTest) {
          data["isTest"] = true;
        }

        // const response = await axios.post(`quiz/otp-verification/verify`, data);
        const response = await axios.post(`api/validateOtp`, data);

        if (response.status == 200 && response.data.status == "success") {
          this.submitLeadPageData();
        } else {
          this.alertMsg = this.translateText(`${response.data.data}`);
          this.showAlert = true;
          setTimeout(
            function () {
              this.showAlert = false;
              this.alertMsg = "";
            }.bind(this),
            3000
          );
        }
      } catch (error) {
        if (error) {
          this.$toasted.show(
            this.translateText("An error occurred. Please try again."),
            {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            }
          );
        }
        throw error;
      } finally {
        this.verifyOTPSpinner = false;
      }
    },

    updateOtpInputValue(value) {
      this.OTPValue = value;
    },
    navigateOtpMethods(method) {
      const isElthClient = this.isBaldenOrElthV3Client.includes(
        this.getPreviewSettings.quizId
      );
      const methodActions = {
        SendOTP: isElthClient ? this.SendOTP : this.authSendOtp,
        verifyOtp: isElthClient ? this.verifyOtp : this.authVerifyOtp,
        resendOtp: this.resendOtp,
      };

      if (methodActions[method]) {
        methodActions[method]();
      } else {
        console.log(
          isElthClient ? `Call Elth OTP Methods` : "Call Normal User Functions"
        );
      }
    },
    createLeadVuexObj() {
      let AllFreeText = [];
      let obj = {};
      let custom_inputs = {};

      // Get all TextAreaInput from  current Page

      this.getAllFreeTextInput.forEach((item) => {
        if (item.type == "textArea" && item.fieldValue != "") {
          AllFreeText.push(item);
        }
      });

      this.getAllFreeTextInput.forEach((item) => {
        if (item.type == "text" && item.fieldValue != "") {
          obj[item.fieldName] = item.fieldValue;
        }
        if (item.type == "email" && item.fieldValue != "") {
          obj[item.fieldName] = item.fieldValue;
        }
        if (
          item.type == "number" &&
          item.text == "Phone Number" &&
          item.fieldValue != ""
        ) {
          obj[item.fieldName] = item.fieldValue;
        }
        if (
          item.type == "number" &&
          item.text == "Date" &&
          item.dateValue != "" &&
          item.monthValue != "" &&
          item.yearValue != ""
        ) {
          if (item.dateFormat == "DDMMYYYY") {
            obj[
              item.fieldName
            ] = `${item.dateValue}${item.dateSeperator}${item.monthValue}${item.dateSeperator}${item.yearValue}`;
          }
          if (item.dateFormat == "MMDDYYYY") {
            obj[
              item.fieldName
            ] = `${item.monthValue}${item.dateSeperator}${item.dateValue}${item.dateSeperator}${item.yearValue}`;
          }
          if (item.dateFormat == "YYYYMMDD") {
            obj[
              item.fieldName
            ] = `${item.yearValue}${item.dateSeperator}${item.monthValue}${item.dateSeperator}${item.dateValue}`;
          }
          if (item.dateFormat == "DDMM") {
            obj[
              item.fieldName
            ] = `${item.dateValue}${item.dateSeperator}${item.monthValue}`;
          }
          if (item.dateFormat == "MMDD") {
            obj[
              item.fieldName
            ] = `${item.monthValue}${item.dateSeperator}${item.dateValue}`;
          }
        }
        if (item.type == "checkbox") {
          obj[item.fieldName] = item.value;
        }
        if (item.type == "address") {
          obj[item.fieldName] = item.fieldValue;
        }
        if (item.type == "fileUpload") {
          obj[item.fieldName] = [
            ...this.getSavedUploadFiles,
            ...item.fieldValue,
          ];
        }
        if (Object.prototype.hasOwnProperty.call(item, "inputType")) {
          if (item.type == "date") {
            if (
              item.dateValue != "" &&
              item.monthValue != "" &&
              item.yearValue != ""
            ) {
              if (item.dateFormat == "DDMMYYYY") {
                custom_inputs[
                  item.fieldName
                ] = `${item.dateValue}${item.dateSeperator}${item.monthValue}${item.dateSeperator}${item.yearValue}`;
              }
              if (item.dateFormat == "MMDDYYYY") {
                custom_inputs[
                  item.fieldName
                ] = `${item.monthValue}${item.dateSeperator}${item.dateValue}${item.dateSeperator}${item.yearValue}`;
              }
              if (item.dateFormat == "YYYYMMDD") {
                custom_inputs[
                  item.fieldName
                ] = `${item.yearValue}${item.dateSeperator}${item.monthValue}${item.dateSeperator}${item.dateValue}`;
              }
              if (item.dateFormat == "DDMM") {
                custom_inputs[
                  item.fieldName
                ] = `${item.dateValue}${item.dateSeperator}${item.monthValue}`;
              }
              if (item.dateFormat == "MMDD") {
                custom_inputs[
                  item.fieldName
                ] = `${item.monthValue}${item.dateSeperator}${item.dateValue}`;
              }
            }
          } else {
            custom_inputs[item.fieldName] = item.fieldValue;
          }
        }
      });

      if (AllFreeText.length) {
        obj["freeTextNote"] = AllFreeText;
      }

      if (Object.keys(custom_inputs).length > 0) {
        obj["custom_inputs"] = custom_inputs;
      }

      return obj;
    },

    UpdateFiles(files) {
      let index = this.getFileUploadCompIndex;
      if (index > -1 && files) {
        this.pageDesign.blocksArray[index].uploadedFiles = [
          ...this.pageDesign.blocksArray[index].uploadedFiles,
          ...files,
        ];
      }
    },
    async validateSpecialClientEmail(UpdateLeadObj) {
      // Baldan Client
      if (
        this.getPreviewSettings.quizId === 2490 &&
        this.getSelectedPage.id == 3776
      ) {
        let data = {
          quizId: this.getPreviewSettings.quizId,
          email: UpdateLeadObj.email,
          api_token: this.apiToken,
        };
        return await axios
          .post("api/validatePyamentEmail", data)
          .then((response) => {
            if (response.data.data == true) {
              return true;
            } else {
              this.$toasted.show("Email does not contain any store", {
                theme: "toasted-primary",
                position: "bottom-center",
                duration: 2000,
              });
              return false;
            }
          })
          .catch(() => {
            this.$toasted.show("Email does not contain any store", {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            });
            return false;
          });
      }
      return true;
    },
    skipLead() {
      this.submitLeadPageData(`skipLead`);
    },

    async CheckZipCode(zipCode) {
      const FlexamatZipCodes = await import(
        "./specialClients/FlexamatZipCodes"
      );
      if (zipCode) {
        return FlexamatZipCodes.default.indexOf(parseInt(zipCode)) !== -1;
      }
    },
    async submitLeadPageData(action) {
      let isSkipLead = typeof action !== "undefined" && action == "skipLead";
      // this.updateFreeTextAreaValue();

      if (
        this.getValidatedValues() ||
        this.isEmailContainSpecialCharacter ||
        isSkipLead
      ) {
        let UpdateLeadObj = await this.createLeadVuexObj();

        await this.updateSavedLeads(UpdateLeadObj);
        const baldanClientValidateEmail = await this.validateSpecialClientEmail(
          UpdateLeadObj
        );

        if (!baldanClientValidateEmail) {
          return false;
        }
        this.checkLeadCounter();
        if (!this.isAllInputEmpty && this.isLastLeadPage()) {
          try {
            this.nextBtnSpinner = true;
            let data = {
              quizId: this.getPreviewSettings.quizId,
              leadData: JSON.stringify(this.getLeadObjfromVuex),
              api_token: this.apiToken,
              quiz_analytic_id: this.getLeadObjfromVuex.quiz_analytic_id,
            };
            if (this.isTest) {
              data["isTest"] = true;
            }

            let { quiz_analytic_id } = this.getSavedLeadID;

            if (quiz_analytic_id) {
              try {
                const response = await axios.post(`/api/saveFinalLead`, data);
                if (response.status == 200) {
                  this.setSavedLeadID(response.data.data);

                  let savedFlexamatZipCode =
                    response.data.data?.zip_code || null;

                  // Custom Logic for FlexamatZipCode
                  const flexamat = this.getEnv?.VUE_APP_FLEXAMAT_QUIZID ?? null;
                  if (
                    flexamat == this.getPreviewSettings.quizId &&
                    savedFlexamatZipCode
                  ) {
                    let isZipCodePresent = await this.CheckZipCode(
                      savedFlexamatZipCode
                    );
                    const flexamateQuizId =
                      this.getEnv?.VUE_APP_FLEXAMAT_JUMPTOQUIZID ?? null;
                    if (isZipCodePresent) {
                      const JumpToPageIndex =
                        this.getTotalPreviewPages.findIndex(
                          (x) => x.id == flexamateQuizId
                        );

                      this.jumpToPreviewPage(JumpToPageIndex - 1);
                    }
                  }

                  // Ikea Furniture Client
                  // if (
                  //   flexamat ==
                  //   this.getPreviewSettings.quizId
                  // ) {
                  //   await this.populateTrackCounter();

                  //   const keys1 = Object.keys(
                  //     this.getFurnitureClientCounterData()
                  //   );
                  //   const keys2 = Object.keys(this.getTrackIkeaClientCounter);

                  //   let CounterObjValue = keys1[0];
                  //   let TrackObjValue = keys2[0];

                  //   if (CounterObjValue == TrackObjValue) {
                  //     let mapPages = {
                  //       dormitorio: "BR.1",
                  //       comedor: "DR.1",
                  //       salÃ³n: "LR.1",
                  //       oficina: "Office",
                  //     };

                  //     const JumpToPreviewPageIndex =
                  //       this.getTotalPreviewPages.findIndex(
                  //         (x) => x.name == mapPages[TrackObjValue]
                  //       );

                  //     await this.updateTrackCounter(TrackObjValue);
                  //     this.jumpToPreviewPage(JumpToPreviewPageIndex);
                  //   }
                  // } else {
                  //   this.goToNextPreviewPage(this.getSelectedPage.value);

                  //   this.nextPageAnimation();
                  // }
                  this.goToNextPreviewPage(this.getSelectedPage.value);

                  this.nextPageAnimation();
                }
              } catch (error) {
                if (error) {
                  console.log("Error", error);
                  this.$toasted.show("Error occured ", {
                    theme: "toasted-primary",
                    type: "error",
                    position: "bottom-center",
                    duration: 2000,
                  });
                }
              }
            } else {
              try {
                let data = {
                  api_token: this.apiToken || this.$route.params.id,
                  quizId: this.getPreviewSettings.quizId,
                  value_type: "Started",
                  started_at: getFormatedUTCTimeStamp(),
                };

                const response = await axios.post(
                  `/api/updateQuizAnalytic`,
                  data
                );
                if (response.status == 200) {
                  let { quiz_analytic_id } = response.data.data;
                  if (quiz_analytic_id) {
                    this.$store.commit(
                      "assignQuizAnaylticsId",
                      quiz_analytic_id
                    );
                    this.submitLeadPageData();
                  }
                }
              } catch (error) {
                if (error) {
                  this.$toasted.show("Error occured ", {
                    theme: "toasted-primary",
                    type: "error",
                    position: "bottom-center",
                    duration: 2000,
                  });
                }
              }
            }
          } catch (error) {
            if (error) {
              this.$toasted.show(
                this.translateText("An error occurred. Please try again."),
                {
                  theme: "toasted-primary",
                  position: "bottom-center",
                  duration: 2000,
                }
              );
            }
            throw error;
          } finally {
            this.nextBtnSpinner = false;
          }
        } else {
          this.goToNextPreviewPage(this.getSelectedPage.value);
          this.nextPageAnimation();
        }
      } else {
        if (!this.isEmailValid) {
          this.alertMsg = this.translateText("Enter a valid email address.");
        } else if (!this.isPhoneValid) {
          this.alertMsg = this.translateText("Enter a valid phone number.");
        } else if (!this.isFreeTextAreaValid) {
          this.alertMsg = this.FreeTextAreaErrorText;
          this.FreeTextAreaErrorText = "";
        } else {
          this.alertMsg = this.translateText(
            "All required fields must be filled."
          );
        }

        this.showAlert = true;
        setTimeout(
          function () {
            this.showAlert = false;
            this.alertMsg = "";
          }.bind(this),
          3000
        );
        this.isEmailValid = true;
        this.isPhoneValid = true;
        this.isFreeTextAreaValid = true;
        this.isDateInputValid = true;
      }
    },
    checkLeadCounter() {
      // insert increment counter code here
      if (this.getPreviewSettings.single_step_status) {
        let currentPageId = this.getSelectedPage.id;
        if (this.getLeadCounterObj.currLeadPageId != currentPageId) {
          this.updateLeadCounter(currentPageId);
        }
      }
      return;
    },
    validateEmail(email) {
      /* eslint-disable-next-line */
      let pattern = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
      return pattern.test(email);
    },
    UpdateDynamicText() {
      if (this.getSavedLeadID.full_name !== "") {
        this.name = this.getSavedLeadID.full_name;
      }
      if (this.getSavedLeadID.email !== "") {
        this.email = this.getSavedLeadID.email;
      }
      if (this.getSavedLeadID.phone_number !== "") {
        this.phone = this.getSavedLeadID.phone_number;
      }
      if (this.getSavedLeadID.date !== "") {
        this.date = this.getSavedLeadID.date;
      }
      if (this.getSavedLeadID.website !== "") {
        this.website = this.getSavedLeadID.website;
      }
      if (this.getSavedLeadID.organisation !== "") {
        this.organisation = this.getSavedLeadID.organisation;
      }
      if (
        Object.prototype.hasOwnProperty.call(this.getSavedLeadID, "address1") &&
        this.getSavedLeadID.address1 !== ""
      ) {
        this.address_1 = this.getSavedLeadID.address1;
      }
      if (
        Object.prototype.hasOwnProperty.call(this.getSavedLeadID, "address2") &&
        this.getSavedLeadID.address2 !== ""
      ) {
        this.address_2 = this.getSavedLeadID.address2;
      }
      if (
        Object.prototype.hasOwnProperty.call(this.getSavedLeadID, "city") &&
        this.getSavedLeadID.city !== ""
      ) {
        this.city = this.getSavedLeadID.city;
      }
      if (
        Object.prototype.hasOwnProperty.call(this.getSavedLeadID, "country") &&
        this.getSavedLeadID.country !== ""
      ) {
        this.country = this.getSavedLeadID.country;
      }
      if (
        Object.prototype.hasOwnProperty.call(this.getSavedLeadID, "state") &&
        this.getSavedLeadID.state !== ""
      ) {
        this.state = this.getSavedLeadID.state;
      }
      if (
        Object.prototype.hasOwnProperty.call(this.getSavedLeadID, "zip_code") &&
        this.getSavedLeadID.zip_code !== ""
      ) {
        this.zip_code = this.getSavedLeadID.zip_code;
      }
    },
    updateSingleUtm(index, key, value) {
      if (index !== undefined && key !== undefined && value !== undefined) {
        if (key == "checkbox") {
          this.pageDesign.blocksArray[index].value = value;
        } else {
          this.pageDesign.blocksArray[index].selectedInfoType.fieldValue =
            value;
        }
      }
    },

    updateUTMData() {
      const queryKeys = Object.keys(this.$route.query);

      if (queryKeys.length > 0) {
        const queryValues = this.$route.query;

        for (const key of queryKeys) {
          const modifiedKey = key.replace(/^utm_/, "");

          const getKeyBlockIndex = this.pageDesign.blocksArray.findIndex(
            (element) =>
              ((element.type === "freeText" ||
                element.type === "customInput") &&
                element.selectedInfoType?.fieldName === modifiedKey) ||
              (element.type === "checkbox" && modifiedKey === "checkbox")
          );

          if (getKeyBlockIndex > -1) {
            this.updateSingleUtm(
              getKeyBlockIndex,
              modifiedKey,
              queryValues[key]
            );
          }
        }
      }
    },
  },
  computed: {
    isBearWithBenefitsClient() {
      return this.getPreviewSettings.quizId
        ? process.env.VUE_APP_BEARWBENEFITS_QUIZID.includes(
            this.getPreviewSettings.quizId
          )
        : false;
    },
    getSavedUploadFiles() {
      return Array.isArray(this.getSavedLeadID?.fileUpload)
        ? this.getSavedLeadID?.fileUpload
        : [];
    },
    isLoadingQuestion() {
      return this.getIsLoadingQuestion;
    },
    getAllCountries() {
      return this.AllCountriesData.length > 0 && this.isCountryAddressFieldExist
        ? this.AllCountriesData
        : [];
    },
    getAllStates() {
      return this.AllStatesData.length > 0 && this.isStateAddressFieldExist
        ? this.AllStatesData
        : [];
    },
    getAllCities() {
      return this.AllCitiesData.length > 0 && this.isCityAddressFieldExist
        ? this.AllCitiesData
        : [];
    },
    isCityAddressFieldExist() {
      let index = this.pageDesign.blocksArray.findIndex(
        (e) => e.type == "address"
      );
      if (index > -1) {
        return this.pageDesign.blocksArray[index].showCity;
      }
      return false;
    },
    isStateAddressFieldExist() {
      let index = this.pageDesign.blocksArray.findIndex(
        (e) => e.type == "address"
      );
      if (index > -1) {
        return this.pageDesign.blocksArray[index].showState;
      }
      return false;
    },
    isCountryAddressFieldExist() {
      let index = this.pageDesign.blocksArray.findIndex(
        (e) => e.type == "address"
      );
      if (index > -1) {
        return this.pageDesign.blocksArray[index].showCountry;
      }
      return false;
    },
    isBaldenOrElthV3Client() {
      return [
        this.getEnv?.VUE_APP_ITALIAN_QUIZID ?? null,
        this.getEnv?.VUE_APP_ELTH_V3_QUIZID ?? null,
      ];
    },
    isPhoneNumberValid() {
      const index = this.pageDesign.blocksArray.findIndex(
        (block) =>
          block.type == "freeText" &&
          block.selectedInfoType.fieldName == "phone_number"
      );

      return index > -1 ? index : null;
    },
    isEmailContainSpecialCharacter() {
      if (this.isEmailInput && this.isEmailInput != "") {
        const lastCharacter = this.isEmailInput.charAt(
          this.isEmailInput.length - 1
        );
        const firstCharacter = this.isEmailInput.charAt(0);
        return lastCharacter == "%" && firstCharacter == "%";
      }
      return false;
    },
    isAllInputEmpty() {
      let arr = [];

      for (let key in this.getLeadObjfromVuex) {
        if (typeof this.getLeadObjfromVuex[key] == "string") {
          if (this.getLeadObjfromVuex[key] == "") {
            arr.push(true);
          } else {
            arr.push(false);
          }
        } else {
          if (this.getLeadObjfromVuex[key] == false) {
            arr.push(true);
          } else {
            arr.push(false);
          }
        }
      }

      return arr.every((item) => item);
      // return false
    },
    isEmailPhoneEmpty() {
      const result = this.getAllFreeTextInput.every((block) => {
        if (block.type == "email") {
          return block.fieldValue == "";
        }
        if (block.type == "number" && block.fieldName == "phone_number") {
          return block.fieldValue == "";
        }

        return false;
      });

      return result;
    },
    StaticButtonElement() {
      const index = this.pageDesign.blocksArray.findIndex(
        (x) => x.type == "button"
      );
      return this.pageDesign.blocksArray[index];
    },

    isVerifyOTPDisable() {
      return this.OTPValue && this.OTPToken;
    },
    showAuthBlock() {
      return this.infoTypeEmailExist && this.verifyOtpView;
    },
    getOTPBlock() {
      const index = this.pageDesign.blocksArray.findIndex(
        (x) => x.type == "Auth"
      );

      if (index > -1) return this.pageDesign.blocksArray[index];
      return null;
    },
    isAuthOTPExist() {
      const index = this.pageDesign.blocksArray.findIndex(
        (x) => x.type == "Auth"
      );

      return index > -1 ? true : false;
    },
    infoTypeEmailExist() {
      const index = this.pageDesign.blocksArray.findIndex(
        (block) =>
          block.type == "freeText" &&
          block.selectedInfoType.fieldName == "email"
      );
      if (index > -1) {
        return true;
      }
      return false;
    },
    isBothEmailAndOTPpresent() {
      return this.infoTypeEmailExist && this.isAuthOTPExist;
    },
    staticButton() {
      const index = this.pageDesign.blocksArray.findIndex(
        (x) => x.type == "button"
      );
      return this.pageDesign.blocksArray[index].staticButton;
    },
    getImageAlign() {
      if (
        this.pageDesign.pageStyles.useMobileImageAlign &&
        !this.isDesktopView
      ) {
        return this.pageDesign.pageStyles.mobileImageAlign;
      } else {
        return this.pageDesign.pageStyles.imageAlign;
      }
    },
    showLeftImage() {
      return (
        (this.isDesktopView &&
          this.pageDesign.imageBlock.style.displayDesktop &&
          this.getImageAlign == "left") ||
        (!this.isDesktopView &&
          this.pageDesign.imageBlock.style.displayMobile &&
          (this.getImageAlign == "left" || this.getImageAlign == "right"))
      );
    },
    showRightImage() {
      return (
        this.isDesktopView &&
        this.pageDesign.imageBlock.style.displayDesktop &&
        this.getImageAlign == "right"
      );
    },

    isEmailInput() {
      const index = this.pageDesign.blocksArray.findIndex(
        (block) =>
          block.type == "freeText" &&
          block.selectedInfoType.fieldName == "email"
      );

      if (index > -1) {
        return this.pageDesign.blocksArray[index].selectedInfoType.fieldValue;
      } else {
        return null;
      }
    },
    isDateInput() {
      const index = this.pageDesign.blocksArray.findIndex(
        (block) =>
          block.type == "freeText" && block.selectedInfoType.fieldName == "date"
      );

      if (index > -1) {
        return this.pageDesign.blocksArray[index].selectedInfoType;
      } else {
        return null;
      }
    },
    ...mapGetters([
      "getIsLoadingQuestion",
      "getLeadCounterObj",
      "getSelectedPage",
      "getUploadedImage",
      "getTotalPages",
      "getPreviewSettings",
      "getTotalPreviewPages",
      "translateText",
      "getPreviewProgressBar",
      "getSavedLeadID",
      "getAnimationSetting",
      "isLastLeadPage",
      "getFurnitureClientCounterData",
      "getTrackIkeaClientCounter",
      "getEnvConst",
    ]),
    italianClientQuizId: () => this.getEnv?.VUE_APP_ITALIAN_QUIZID ?? null,
    elthV3ClientQuizId: () => this.getEnv?.VUE_APP_ELTH_V3_QUIZID ?? null,
    allowedAuthUser: () => this.getEnv?.VUE_APP_ALLOWED_AUTH_USER_ID ?? null,
    //  allowedAuthQuizKey:['/quiz/edodDrpzSDYRKMvgYiYVMS138600','/quiz/xtEn6GvvcmZr9d3LxskWMS249200']
    allowedAuthQuizKey: () =>
      this.getEnv?.VUE_APP_ALLOWED_AUTH_USER_QUIZKEY ?? null,
    getEnv() {
      // ...mapGetters(["getEnvConst"]),
      // getEnvConst
      return this.getEnvConst;
    },
    getLeadObjfromVuex() {
      window.quizell.leadData = this.getSavedLeadID;
      return this.getSavedLeadID;
    },

    ProgressBarValues() {
      return this.getPreviewProgressBar;
    },
    CurrentPage() {
      return this.getSelectedPage;
    },
    IsImageAdded() {
      if (
        this.getImageSrc(this.pageDesign.imageBlock, this.isDesktopView) !==
          "" ||
        this.getUploadedImage
      )
        return true;
      return false;
    },
    IsImageVariableWidthActive() {
      return this.IsImageAdded && this.getImageAlign != "no" ? true : false;
    },
    imageAddedLayout() {
      if (this.getImageAlign == "no") return "col-12";
      if (!this.isDesktopView) return "col-12";
      return "col-12 col-xs-12 col-sm-6 col-md-6  ";
    },
    getAllFreeTextInput() {
      const InputArray = [];
      this.pageDesign.blocksArray.forEach((block) => {
        if (
          block.type == "freeText" &&
          block.selectedInfoType.fieldName !== "date"
        ) {
          InputArray.push(block.selectedInfoType);
        }
        if (block.type == "customInput") {
          if (block.selectedInfoType.type == "date") {
            const obj = {
              ...block.selectedInfoType,
              dateFormat: block.selectedDateFormat,
              dateSeperator: block.selectedDateFormatSeperator,
            };
            obj["inputType"] = "customInput";

            InputArray.push(obj);
          } else {
            const obj = { ...block.selectedInfoType };
            obj["inputType"] = "customInput";
            InputArray.push(obj);
          }
        }
        if (block.type == "freeTextArea") {
          const obj = {
            fieldName: "freeTextNote",
            text: "Free Text Area",
            type: "textArea",
            fieldValue: block.LeadTextValue,
          };
          InputArray.push(obj);
        }
        if (
          block.type == "freeText" &&
          block.selectedInfoType.fieldName == "date"
        ) {
          const obj = {
            ...block.selectedInfoType,
            dateFormat: block.selectedDateFormat,
            dateSeperator: block.selectedDateFormatSeperator,
          };

          InputArray.push(obj);
        }
        if (block.type == "checkbox") {
          let obj = {
            fieldName: "terms_conditions",
            type: "checkbox",
            link: block.link,
            value: block.value,
          };
          InputArray.push(obj);
        }
        if (block.type == "address") {
          const obj = {
            fieldName: "address",
            text: "address",
            type: "address",
            fieldValue: {},
          };

          if (block.AddressLine1Input != "") {
            obj.fieldValue["AddressLine1Input"] = block.AddressLine1Input;
          }
          if (block.AddressLine2Input != "") {
            obj.fieldValue["AddressLine2Input"] = block.AddressLine2Input;
          }
          if (block.CityInput != "") {
            obj.fieldValue["CityInput"] = block.CityInput;
          }
          if (block.CountryInput != "") {
            obj.fieldValue["CountryInput"] = block.CountryInput;
          }
          if (block.StateInput != "") {
            obj.fieldValue["StateInput"] = block.StateInput;
          }
          if (block.ZipCodeInput != "") {
            obj.fieldValue["ZipCodeInput"] = block.ZipCodeInput;
          }
          InputArray.push(obj);
        }
        if (block.type == "fileUpload") {
          let obj = {
            fieldName: "fileUpload",
            text: "fileUpload",
            type: "fileUpload",
            fieldValue: block.uploadedFiles,
          };

          InputArray.push(obj);
        }
      });
      return InputArray;
    },

    findCheckboxIndexes() {
      let indexes = [];
      for (let i = 0; i < this.pageDesign.blocksArray.length; i++) {
        if (this.pageDesign.blocksArray[i].type == "checkbox") {
          indexes.push(i);
        }
      }
      return indexes;
    },
    getDateInputIndex() {
      const index = this.pageDesign.blocksArray.findIndex(
        (block) =>
          block.type == "freeText" && block.selectedInfoType.fieldName == "date"
      );
      if (index > -1) {
        return index;
      } else {
        return null;
      }
    },
    getCustomDateInputIndex() {
      const index = this.pageDesign.blocksArray.findIndex(
        (block) =>
          block.type == "customInput" && block.selectedInfoType.type == "date"
      );
      if (index > -1) {
        return index;
      } else {
        return null;
      }
    },
    getFileUploadCompIndex() {
      const index = this.pageDesign.blocksArray.findIndex(
        (block) => block.type == "fileUpload"
      );
      return index;
    },
  },
  mounted() {
    this.UpdateDynamicText();

    this.updateUTMData();
    // Elth client custom data
    this.alreadyRecordStatusView = false;

    if (this.isCountryAddressFieldExist) {
      this.importCountryModule();
    }
    if (this.isCityAddressFieldExist) {
      this.importCityModule();
    }
    if (this.isStateAddressFieldExist) {
      this.importStateModule();
    }

    if (this.findCheckboxIndexes.length > 0) {
      this.findCheckboxIndexes.forEach((index) => {
        let { preselectCheckbox } = this.pageDesign.blocksArray[index];
        if (preselectCheckbox) {
          this.pageDesign.blocksArray[index].value = true;
        }
      });
    }
  },
  created() {
    this.pageDesign = this.getLeadPageValues();

    const DateInputIndex = this.getDateInputIndex;
    if (DateInputIndex) {
      this.dateInputValue =
        this.pageDesign.blocksArray[DateInputIndex].selectedInfoType.dateValue;
      this.monthInputValue =
        this.pageDesign.blocksArray[DateInputIndex].selectedInfoType.monthValue;
      this.yearInputValue =
        this.pageDesign.blocksArray[DateInputIndex].selectedInfoType.yearValue;
      this.datePickerValue =
        this.pageDesign.blocksArray[
          DateInputIndex
        ].selectedInfoType.datePickerValue;
    }
    const CustomDateInputIndex = this.getCustomDateInputIndex;
    if (CustomDateInputIndex) {
      this.dateCustomInputValue =
        this.pageDesign.blocksArray[
          CustomDateInputIndex
        ].selectedInfoType.dateValue;
      this.monthCustomInputValue =
        this.pageDesign.blocksArray[
          CustomDateInputIndex
        ].selectedInfoType.monthValue;
      this.yearCustomInputValue =
        this.pageDesign.blocksArray[
          CustomDateInputIndex
        ].selectedInfoType.yearValue;
      this.datePickerCustomValue =
        this.pageDesign.blocksArray[
          CustomDateInputIndex
        ].selectedInfoType.datePickerValue;
    }
  },
  watch: {
    datePickerValue(newVal, oldVal) {
      if (newVal !== oldVal) {
        let fullDate = newVal;
        let parts = fullDate.split("-");
        if (parts.length) {
          this.pageDesign.blocksArray[
            this.getDateInputIndex
          ].selectedInfoType.yearValue = parts[0];
          this.pageDesign.blocksArray[
            this.getDateInputIndex
          ].selectedInfoType.monthValue = parts[1];
          this.pageDesign.blocksArray[
            this.getDateInputIndex
          ].selectedInfoType.dateValue = parts[2];
        }
      }
    },
    datePickerCustomValue(newVal, oldVal) {
      if (newVal !== oldVal) {
        let fullDate = newVal;
        let parts = fullDate.split("-");
        if (parts.length) {
          this.pageDesign.blocksArray[
            this.getCustomDateInputIndex
          ].selectedInfoType.yearValue = parts[0];
          this.pageDesign.blocksArray[
            this.getCustomDateInputIndex
          ].selectedInfoType.monthValue = parts[1];
          this.pageDesign.blocksArray[
            this.getCustomDateInputIndex
          ].selectedInfoType.dateValue = parts[2];
        }
      }
    },
    dateInputValue(newVal, oldVal) {
      if (newVal > 31) {
        this.dateInputValue = oldVal;
        this.alertMsg = this.translateText("Invalid date entered.");
        this.showAlert = true;
        setTimeout(
          function () {
            this.showAlert = false;
            this.alertMsg = "";
          }.bind(this),
          3000
        );
      } else {
        this.pageDesign.blocksArray[
          this.getDateInputIndex
        ].selectedInfoType.dateValue = this.dateInputValue;
      }
    },
    dateCustomInputValue(newVal, oldVal) {
      if (newVal > 31) {
        this.dateCustomInputValue = oldVal;
        this.alertMsg = this.translateText("Invalid date entered.");
        this.showAlert = true;
        setTimeout(
          function () {
            this.showAlert = false;
            this.alertMsg = "";
          }.bind(this),
          3000
        );
      } else {
        this.pageDesign.blocksArray[
          this.getCustomDateInputIndex
        ].selectedInfoType.dateValue = this.dateCustomInputValue;
      }
    },
    monthInputValue(newVal, oldVal) {
      if (newVal > 12) {
        this.monthInputValue = oldVal;
        this.alertMsg = this.translateText("Invalid month entered.");
        this.showAlert = true;
        setTimeout(
          function () {
            this.showAlert = false;
            this.alertMsg = "";
          }.bind(this),
          3000
        );
      } else {
        this.pageDesign.blocksArray[
          this.getDateInputIndex
        ].selectedInfoType.monthValue = this.monthInputValue;
      }
    },
    monthCustomInputValue(newVal, oldVal) {
      if (newVal > 12) {
        this.monthCustomInputValue = oldVal;
        this.alertMsg = this.translateText("Invalid month entered.");
        this.showAlert = true;
        setTimeout(
          function () {
            this.showAlert = false;
            this.alertMsg = "";
          }.bind(this),
          3000
        );
      } else {
        this.pageDesign.blocksArray[
          this.getCustomDateInputIndex
        ].selectedInfoType.monthValue = this.monthCustomInputValue;
      }
    },
    yearInputValue(newVal, oldVal) {
      if (newVal > 9999) {
        this.yearInputValue = oldVal;
        this.alertMsg = this.translateText("Invalid year entered.");
        this.showAlert = true;
        setTimeout(
          function () {
            this.showAlert = false;
            this.alertMsg = "";
          }.bind(this),
          3000
        );
      } else {
        this.pageDesign.blocksArray[
          this.getDateInputIndex
        ].selectedInfoType.yearValue = this.yearInputValue;
      }
    },
    yearCustomInputValue(newVal, oldVal) {
      if (newVal > 9999) {
        this.yearCustomInputValue = oldVal;
        this.alertMsg = this.translateText("Invalid year entered.");
        this.showAlert = true;
        setTimeout(
          function () {
            this.showAlert = false;
            this.alertMsg = "";
          }.bind(this),
          3000
        );
      } else {
        this.pageDesign.blocksArray[
          this.getCustomDateInputIndex
        ].selectedInfoType.yearValue = this.yearCustomInputValue;
      }
    },

    getTotalPages(newVal, oldVal) {
      if (oldVal.length < newVal.length) {
        this.count++;
      }
      this.pageDesign = this.getLeadPageValues();
    },
  },
};
</script>
<style scoped>
.slideRTL {
  animation-name: slide-in;
  animation-duration: var(--animation-speed);
  animation-timing-function: forwards;
  animation-fill-mode: both;
}

.slideLTR {
  animation-name: slide-out;
  animation-duration: var(--animation-speed);
  animation-timing-function: forwards;
  animation-fill-mode: both;
}

.alert-text {
  padding: 4px 12px !important;
  margin: 0px;
  width: fit-content;
  border-radius: 6px !important;
}

.lead-checkbox.custom-control-label::before {
  border: #0397cc solid 2px;
}

.lead-email-btn {
  border-bottom: 1px solid #e0dee2;
}

.lead-email-btn.dropdown-toggle::after {
  float: right;
  margin-top: 12px;
}

.condition-text {
  color: #0397cc;
}

.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none;
}

.input-element {
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
}

.big-div {
  padding: 0px;
}

.checkboxDiv input[type="checkbox"] {
  accent-color: var(--selectedCheckboxColor);
}

/* Sticky CSS */
.big-div.sticky {
  position: -webkit-sticky;
  /* Safari */
  position: sticky;
  top: 0;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

.text-input {
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  color: var(--inputTextColor);
}

.text-input::placeholder {
  color: var(--inputPlaceholderColor);
}

.input-element::placeholder {
  color: var(--inputPlaceholderColor);
}

.CustomTextInput {
  color: var(--inputColor) !important;
}

/* DatePicker styles */
.CustomDatePicker >>> button svg path {
  fill: var(--inputColor) !important;
}

.CustomDatePicker >>> label.form-control {
  color: var(--inputColor) !important;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.defaultCalendar {
  width: 100%;
  padding: 0 5px;
  border: none;
  color: var(--inputColor) !important;
  background: transparent !important;
}
input[type="date"] {
  color: var(--inputColor) !important;
}
input[type="date"]::placeholder {
  color: var(--inputColor) !important;
}

input[type="date"]:focus {
  border-color: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  outline: 0 none !important;
}

.AuthTextInput:focus {
  border-color: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  outline: 0 none !important;
}

input::-webkit-datetime-edit {
  position: relative;
  left: 0px;
}

input::-webkit-datetime-edit-fields-wrapper {
  position: relative;
  left: 0px;
}

input[type="date"]::-webkit-datetime-edit-year-field {
  color: var(--inputColor) !important;
}

input[type="date"]::-webkit-datetime-edit-month-field {
  color: var(--inputColor) !important;
}

input[type="date"]::-webkit-datetime-edit-day-field {
  color: var(--inputColor) !important;
}

.flex-grow-1-sm-md {
  flex-grow: 1;
}

.Btn-flex-grow-1-sm-md {
  flex-grow: 0;
}

.showAlert {
  /* width:36%; */
  width: 100%;
}

@media (min-width: 650px) and (max-width: 768px) {
  .flex-grow-1-sm-md {
    flex-grow: 0;
    width: 70%;
  }

  .Btn-flex-grow-1-sm-md {
    flex-grow: 1;
  }
}

@media (max-width: 415px) {
  .flex-grow-1-sm-md {
    flex-grow: 0;
    width: 70%;
  }

  .Btn-flex-grow-1-sm-md {
    flex-grow: 1;
  }
}

@media (max-width: 576px) {
  .showAlert {
    width: 80%;
  }
}

.telephoneInput >>> input {
  background: transparent;

  color: var(--inputColor) !important;
  /* color: #000000 !important; */
}

.telephoneInput >>> input.vti__input::placeholder {
  color: var(--inputPlaceholderColor) !important;
}
.telephoneInput >>> .vti__dropdown .vti__dropdown-arrow {
  color: var(--inputPlaceholderColor) !important;
}

.telephoneInput >>> ul.vti__dropdown-list {
  position: unset;
}

/* MultiSelect Styles */

.select-div >>> .multiSelect {
  text-align: start;
  direction: ltr !important;
}

.select-div >>> .multiSelect .multiselect__placeholder {
  color: var(--inputPlaceholderColor);
  font-weight: 400;
  font-size: 16px;
}
.select-div >>> .multiSelect .multiselect__single {
  font-weight: 400;
  color: var(--inputColor);
}

.select-div >>> .multiselect__content-wrapper {
  text-align: left !important;
  direction: ltr !important;
  z-index: 100;
  position: relative;
}
/* DropdownStyles */
.select-div >>> .multiselect .multiselect__option--highlight,
.select-div >>> .multiselect .multiselect__option--highlight:after {
  background: var(--dropdownHoverColor) !important;
}
.tooltip-zIndex {
  position: relative;
  z-index: 11111;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
